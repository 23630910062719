
function stringify(val) {
    return JSON.stringify(val)
}

const horizontalStripe = 1
const verticalStripe = 0



// const verticalLength = 15
// const verticalLastIndex = verticalLength - 1

// const centerVerticalIndex = verticalLastIndex / 2
const n1CenterIndex = 2
const n2CenterIndex = 8
const n3CenterIndex = 14



const n1 = [
    stringify([5, 2]),
    stringify([7, 2]),
    stringify([9, 2]),
    stringify([10, 2]),
    stringify([10, 3]),
]

const n2 = [

    stringify([4, 8]),
    stringify([6, 8]),
    stringify([8, 8]),
    stringify([10, 8]),
    stringify([11, 7]),
    stringify([11, 8]),
    stringify([12, 7]),
    stringify([12, 8]),
    stringify([12, 9]),


]

const n3 = [

    stringify([1, 14]),
    stringify([3, 14]),
    stringify([5, 14]),
    stringify([7, 14]),
    stringify([9, 14]),
    stringify([10, 14]),
    stringify([10, 15]),
    stringify([11, 13]),
    stringify([11, 14]),
    stringify([11, 15]),
    stringify([12, 13]),
    stringify([12, 14]),
    stringify([12, 15]),
    stringify([12, 16]),




]

const acceptedCoords = [

    //n1 verticle stripe
    stringify([verticalStripe, n1[0], 0, 'stripe']),
    stringify([verticalStripe, n1[1], 0, 'stripe']),
    stringify([verticalStripe, n1[2], 0, 'stripe']),
    stringify([verticalStripe, n1[3], 0, 'stripe']),
    stringify([verticalStripe, n1[4], 0, 'stripe']),

    stringify([verticalStripe, n1[0], -1, 'stripe']),
    stringify([verticalStripe, n1[1], -1, 'stripe']),
    stringify([verticalStripe, n1[2], -1, 'stripe']),
    stringify([verticalStripe, n1[3], -1, 'stripe']),
    stringify([verticalStripe, n1[4], -1, 'stripe']),


    stringify([verticalStripe, n1[0], 1, 'stripe']),
    stringify([verticalStripe, n1[1], 1, 'stripe']),


    //n1 horizontal stripe
    stringify([horizontalStripe, n1[0], 0, 'stripe']),
    stringify([horizontalStripe, n1[1], 0, 'stripe']),
    stringify([horizontalStripe, n1[2], 0, 'stripe']),
    stringify([horizontalStripe, n1[3], 0, 'stripe']),
    stringify([horizontalStripe, n1[4], 0, 'stripe']),

    stringify([horizontalStripe, n1[0], -1, 'stripe']),
    stringify([horizontalStripe, n1[1], -1, 'stripe']),
    stringify([horizontalStripe, n1[2], -1, 'stripe']),
    stringify([horizontalStripe, n1[3], -1, 'stripe']),
    stringify([horizontalStripe, n1[4], -1, 'stripe']),


    stringify([horizontalStripe, n1[3], 1, 'stripe']),



    //n1 triangle
    stringify([verticalStripe, n1[0], 0, 'triangle2']),
    stringify([verticalStripe, n1[1], 0, 'triangle2']),
    stringify([verticalStripe, n1[2], 0, 'triangle2']),
    stringify([verticalStripe, n1[3], 0, 'triangle2']),
    stringify([verticalStripe, n1[4], 0, 'triangle2']),

    stringify([verticalStripe, n1[0], -1, 'triangle2']),
    stringify([verticalStripe, n1[1], -1, 'triangle2']),
    stringify([verticalStripe, n1[2], -1, 'triangle2']),
    stringify([verticalStripe, n1[3], -1, 'triangle2']),
    stringify([verticalStripe, n1[4], -1, 'triangle2']),

    stringify([verticalStripe, n1[2], 1, 'triangle2']),


    //n2 verticle stripe
    stringify([verticalStripe, n2[0], 0, 'stripe']),
    stringify([verticalStripe, n2[1], 0, 'stripe']),
    stringify([verticalStripe, n2[2], 0, 'stripe']),
    stringify([verticalStripe, n2[3], 0, 'stripe']),


    stringify([verticalStripe, n2[0], -1, 'stripe']),
    stringify([verticalStripe, n2[1], -1, 'stripe']),
    stringify([verticalStripe, n2[2], -1, 'stripe']),
    stringify([verticalStripe, n2[3], -1, 'stripe']),
    stringify([verticalStripe, stringify([10,7]), -1, 'stripe']),
    stringify([verticalStripe, stringify([9,7]), -1, 'stripe']),
    stringify([verticalStripe, stringify([10,9]), -1, 'stripe']),
    stringify([verticalStripe, stringify([9,8]), -1, 'stripe']),
    stringify([verticalStripe, stringify([2,8]), -1, 'stripe']),


    stringify([verticalStripe, n2[0], 1, 'stripe']),
    stringify([verticalStripe, n2[1], 1, 'stripe']),
    stringify([verticalStripe, n2[2], 1, 'stripe']),

    //n2 horizontal stripe

    stringify([horizontalStripe, n2[4], 0, 'stripe']),
    stringify([horizontalStripe, n2[6], 0, 'stripe']),
    stringify([horizontalStripe, n2[7], 0, 'stripe']),

    stringify([horizontalStripe, n2[0], -1, 'stripe']),
    stringify([horizontalStripe, n2[1], -1, 'stripe']),
    stringify([horizontalStripe, n2[2], -1, 'stripe']),
    stringify([horizontalStripe, n2[3], -1, 'stripe']),
    stringify([horizontalStripe, n2[4], -1, 'stripe']),
    stringify([horizontalStripe, n2[5], -1, 'stripe']),
    stringify([horizontalStripe, n2[6], -1, 'stripe']),
    stringify([horizontalStripe, n2[7], -1, 'stripe']),
    stringify([horizontalStripe, n2[8], -1, 'stripe']),

    stringify([horizontalStripe, n2[6], 1, 'stripe']),


    //n2 triangle
    stringify([verticalStripe, n2[3], 0, 'triangle2']),
    stringify([verticalStripe, n2[4], 0, 'triangle2']),
    stringify([verticalStripe, n2[5], 0, 'triangle2']),

    stringify([verticalStripe, n2[3], -1, 'triangle2']),
    stringify([verticalStripe, n2[4], -1, 'triangle2']),
    stringify([verticalStripe, n2[5], -1, 'triangle2']),

    stringify([verticalStripe, n2[3], 1, 'triangle2']),

    //n3 vertical stripe
    stringify([verticalStripe, n3[0], 0, 'stripe']),
    stringify([verticalStripe, n3[1], 0, 'stripe']),
    stringify([verticalStripe, n3[2], 0, 'stripe']),
    stringify([verticalStripe, n3[3], 0, 'stripe']),

    stringify([verticalStripe, n3[0], -1, 'stripe']),
    stringify([verticalStripe, n3[1], -1, 'stripe']),
    stringify([verticalStripe, n3[2], -1, 'stripe']),
    stringify([verticalStripe, n3[3], -1, 'stripe']),
    stringify([verticalStripe, stringify([8,14]), -1, 'stripe']),
    stringify([verticalStripe, stringify([8,15]), -1, 'stripe']),


    stringify([verticalStripe, n3[0], 1, 'stripe']),
    stringify([verticalStripe, n3[1], 1, 'stripe']),
    stringify([verticalStripe, n3[2], 1, 'stripe']),


    //n3 horizontal stripe
    stringify([horizontalStripe, n3[7], 0, 'stripe']),
    stringify([horizontalStripe, n3[10], 0, 'stripe']),
    stringify([horizontalStripe, n3[11], 0, 'stripe']),

    stringify([horizontalStripe, n3[7], -1, 'stripe']),
    stringify([horizontalStripe, n3[10], -1, 'stripe']),
    stringify([horizontalStripe, n3[11], -1, 'stripe']),
    stringify([horizontalStripe, stringify([12,15]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([11,14]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([10,14]), -1, 'stripe']),

    stringify([horizontalStripe, n3[10], 1, 'stripe']),



    //n3 triangle
    stringify([verticalStripe, n3[4], 0, 'triangle2']),
    stringify([verticalStripe, n3[5], 0, 'triangle2']),
    stringify([verticalStripe, n3[6], 0, 'triangle2']),

    stringify([verticalStripe, n3[4], -1, 'triangle2']),
    stringify([verticalStripe, n3[5], -1, 'triangle2']),
    stringify([verticalStripe, n3[6], -1, 'triangle2']),

    stringify([verticalStripe, n3[4], 1, 'triangle2']),

]

export const eiffeltallene = {

    n1: n1,
    n2: n2,
    n3: n3,
    acceptedCoords: acceptedCoords,

    n1CenterIndex: n1CenterIndex,
    n2CenterIndex: n2CenterIndex,
    n3CenterIndex: n3CenterIndex,

    shape: [['stripe', 0], ['stripe', 1], ['triangle2', 0]],

    name: 'Eiffeltallene',
    formula: 'n ^ 2 / 2 + n * 5 / 2 + 2',



}