import { string } from "mathjs"

function stringify(val) {
    return JSON.stringify(val)
}

// const horizontalStripe = 1
const verticalStripe = 0



// const verticalLength = 15
// const verticalLastIndex = verticalLength - 1

// const centerVerticalIndex = verticalLastIndex / 2
const n1CenterIndex = 2
const n2CenterIndex = 8
const n3CenterIndex = 14



const n1 = [
    stringify([6, 2]),
    stringify([6, 3]),
    stringify([8, 2]),
    stringify([8, 3]),
]

const n2 = [

    stringify([6, 9]),
    stringify([7, 6]),
    stringify([7, 7]),
    stringify([7, 8]),
    stringify([9, 6]),
    stringify([9, 7]),
    stringify([9, 8]),
    stringify([11, 6]),
    stringify([11, 8]),




]

const n3 = [


    stringify([3, 15]),
    stringify([3, 16]),
    stringify([5, 15]),
    stringify([5, 16]),

    stringify([6, 12]),
    stringify([6, 13]),
    stringify([6, 14]),
    stringify([6, 15]),

    stringify([8, 12]),
    stringify([8, 13]),
    stringify([8, 14]),
    stringify([8, 15]),

    stringify([10, 12]),
    stringify([10, 13]),
    stringify([10, 14]),
    stringify([10, 15]),

    stringify([12, 12]),
    stringify([12, 15]),






]

const acceptedCoords = [

    //n1 stripe
    
    stringify([verticalStripe, n1[0], 0, 'stripe']),
    stringify([verticalStripe, n1[1], 0, 'stripe']),
    stringify([verticalStripe, n1[2], 0, 'stripe']),
    stringify([verticalStripe, n1[3], 0, 'stripe']),


    stringify([verticalStripe, n1[0], -1, 'stripe']),
    stringify([verticalStripe, n1[1], -1, 'stripe']),
    stringify([verticalStripe, n1[2], -1, 'stripe']),
    stringify([verticalStripe, n1[3], -1, 'stripe']),

    stringify([verticalStripe, n1[0], 1, 'stripe']),
    stringify([verticalStripe, n1[1], 1, 'stripe']),

    //n1 rectangle
    stringify([verticalStripe, n1[0], 0, 'rectangle']),
    stringify([verticalStripe, n1[1], 0, 'rectangle']),
    stringify([verticalStripe, n1[2], 0, 'rectangle']),
    stringify([verticalStripe, n1[3], 0, 'rectangle']),

    stringify([verticalStripe, n1[0], -1, 'rectangle']),
    stringify([verticalStripe, n1[1], -1, 'rectangle']),
    stringify([verticalStripe, n1[2], -1, 'rectangle']),
    stringify([verticalStripe, n1[3], -1, 'rectangle']),

    stringify([verticalStripe, n1[0], 1, 'rectangle']),


    //n2 stripe
    stringify([verticalStripe, n2[1], 0, 'stripe']),
    stringify([verticalStripe, n2[2], 0, 'stripe']),
    stringify([verticalStripe, n2[3], 0, 'stripe']),
    stringify([verticalStripe, n2[4], 0, 'stripe']),
    stringify([verticalStripe, n2[6], 0, 'stripe']),

    stringify([verticalStripe, n2[1], -1, 'stripe']),
    stringify([verticalStripe, n2[2], -1, 'stripe']),
    stringify([verticalStripe, n2[3], -1, 'stripe']),
    stringify([verticalStripe, n2[4], -1, 'stripe']),
    stringify([verticalStripe, n2[6], -1, 'stripe']),
    stringify([verticalStripe, stringify([5,6]), -1, 'stripe']),
    stringify([verticalStripe, stringify([5,7]), -1, 'stripe']),
    stringify([verticalStripe, stringify([5,8]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,9]), -1, 'stripe']),

    stringify([verticalStripe, n2[1], 1, 'stripe']),
    stringify([verticalStripe, n2[3], 1, 'stripe']),

    //n2 rectangle
    stringify([verticalStripe, stringify([8,7]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([8,8]), 0, 'rectangle']),
    stringify([verticalStripe, n2[2], 0, 'rectangle']),

    stringify([verticalStripe, stringify([5,9]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([6,7]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([6,8]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([6,9]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([8,7]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([8,8]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([8,9]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([10,7]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([10,9]), -1, 'rectangle']),
    
   


    //n3 stripe
    stringify([verticalStripe, n3[4], 0, 'stripe']),
    stringify([verticalStripe, n3[5], 0, 'stripe']),
    stringify([verticalStripe, n3[6], 0, 'stripe']),
    stringify([verticalStripe, n3[7], 0, 'stripe']),
    stringify([verticalStripe, n3[8], 0, 'stripe']),
    stringify([verticalStripe, n3[11], 0, 'stripe']),

    stringify([verticalStripe, n3[4], -1, 'stripe']),
    stringify([verticalStripe, n3[5], -1, 'stripe']),
    stringify([verticalStripe, n3[6], -1, 'stripe']),
    stringify([verticalStripe, n3[7], -1, 'stripe']),
    stringify([verticalStripe, n3[8], -1, 'stripe']),
    stringify([verticalStripe, n3[11], -1, 'stripe']),
    stringify([verticalStripe, stringify([4,12]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,13]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,14]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,15]), -1, 'stripe']),
    stringify([verticalStripe, stringify([1,15]), -1, 'stripe']),
    stringify([verticalStripe, stringify([1,16]), -1, 'stripe']),

    stringify([verticalStripe, n3[4], 1, 'stripe']),
    stringify([verticalStripe, n3[7], 1, 'stripe']),

    //n3 rectangle
    stringify([verticalStripe, stringify([8,13]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([8,14]), 0, 'rectangle']),

    stringify([verticalStripe, stringify([6,13]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([6,14]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([6,15]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([3,16]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([8,13]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([8,14]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([8,15]), -1, 'rectangle']),
]

export const hundetallene = {

    n1: n1,
    n2: n2,
    n3: n3,
    acceptedCoords: acceptedCoords,

    n1CenterIndex: n1CenterIndex,
    n2CenterIndex: n2CenterIndex,
    n3CenterIndex: n3CenterIndex,

    shape: [['stripe', 0], ['rectangle', 0]],

    name: 'Hundetallene',
    formula: '2 n ^ 2 + 3 - n',



}