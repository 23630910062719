
function stringify(val) {
    return JSON.stringify(val)
}

const horizontalStripe = 1
// const verticalStripe = 0



// const verticalLength = 15
// const verticalLastIndex = verticalLength - 1

// const centerVerticalIndex = verticalLastIndex / 2
// const n1CenterIndex = 2
// const n2CenterIndex = 8
// const n3CenterIndex = 14




const n1 = [
    stringify([5, 2]),
    stringify([7, 1]),
    stringify([7, 3]),
    stringify([9, 2]),
]

const n2 = [
    stringify([5, 7]),
    stringify([5, 8]),
    stringify([7, 6]),
    stringify([7, 9]),
    stringify([9, 7]),
    stringify([9, 8]),

]

const n3 = [
    stringify([5, 13]),
    stringify([5, 14]),
    stringify([5, 15]),
    stringify([7, 12]),
    stringify([7, 16]),
    stringify([9, 13]),
    stringify([9, 14]),
    stringify([9, 15]),

]

const acceptedCoords = [
    stringify([horizontalStripe, n1[0], 0, 'stripe']),
    stringify([horizontalStripe, n1[1], 0, 'stripe']),
    stringify([horizontalStripe, n1[2], 0, 'stripe']),
    stringify([horizontalStripe, n1[3], 0, 'stripe']),

    stringify([horizontalStripe, n1[0], -1, 'stripe']),
    stringify([horizontalStripe, n1[1], -1, 'stripe']),
    stringify([horizontalStripe, n1[2], -1, 'stripe']),
    stringify([horizontalStripe, n1[3], -1, 'stripe']),


    stringify([horizontalStripe, n2[0], 0, 'stripe']),
    stringify([horizontalStripe, n2[4], 0, 'stripe']),

    stringify([horizontalStripe, n2[0], -1, 'stripe']),
    stringify([horizontalStripe, n2[4], -1, 'stripe']),
    stringify([horizontalStripe, n2[1], -1, 'stripe']),
    stringify([horizontalStripe, n2[2], -1, 'stripe']),
    stringify([horizontalStripe, n2[3], -1, 'stripe']),
    stringify([horizontalStripe, n2[5], -1, 'stripe']),
   


    stringify([horizontalStripe, n3[0], 0, 'stripe']),
    stringify([horizontalStripe, n3[5], 0, 'stripe']),

    stringify([horizontalStripe, n3[0], -1, 'stripe']),
    stringify([horizontalStripe, n3[5], -1, 'stripe']),
    stringify([horizontalStripe, n3[1], -1, 'stripe']),
    stringify([horizontalStripe, n3[6], -1, 'stripe']),
]


export const ovaltallene = {

    n1: n1,
    n2: n2,
    n3: n3,
    acceptedCoords: acceptedCoords,

    shape: [['stripe', 1]],


    name: 'Ovaltallene',
    formula: '2 n + 2',

}