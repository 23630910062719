import React, { useEffect, useLayoutEffect, useRef } from "react"
import { FirstContainerRectangle, SecondContainerRectangle, ThirdContainerRectangle } from "../../shapes/displayShapes/rectangle"
import { FirstContainerStripe, SecondContainerStripe, ThirdContainerStripe } from "../../shapes/displayShapes/stripe"
import { FirstContainerTriangle1, SecondContainerTriangle1, ThirdContainerTriangle1 } from "../../shapes/displayShapes/triangle1"
import { FirstContainerTriangle2, SecondContainerTriangle2, ThirdContainerTriangle2 } from "../../shapes/displayShapes/triangle2"
import { FirstContainerTriangle3, SecondContainerTriangle3, ThirdContainerTriangle3 } from "../../shapes/displayShapes/triangle3"
import { FirstContainerTriangle4, SecondContainerTriangle4, ThirdContainerTriangle4 } from "../../shapes/displayShapes/triangle4"

export const RenderDot = ({ draggedElementCoords, className, isShapeShake, onLayout, ringOpacity = 0, nearestCoord, indexArr, widthId, heightId, containerWidth, containerHeight, isAlternate, selectedStripe, selectedForm, verticalLastIndex, centerVerticalIndex, horizontalLastIndex, n1LastIndex, n2LastIndex, n3LastIndex, acceptedCoords, droppedCoords, onDragStart }) => {

    const ref = useRef(null)

    const getPosition = () => {
        const { x, y } = ref.current.getBoundingClientRect()
        onLayout([x, y])

    }

    useLayoutEffect(() => { getPosition() }, [])

    useEffect(() => { window.addEventListener('resize', getPosition) }, [])

    // const currentCoord = droppedCoords?.find((item) => {
    //     return JSON.parse(item)[1] === indexArr
    // })
    const currentCoords = droppedCoords?.filter((item) => {
        return JSON.parse(item)[1] === indexArr
    })

    // const isAccepted = (droppedCoords, acceptedCoords, nearestCoord, selectedStripe, ringOpacity) => {
    //     // if (nearestCoord) {
    //     //     const accept = acceptedCoords.find(item => JSON.parse(item)[1] === nearestCoord)
    //     //     return { isAccepted: accept && ringOpacity === 1 ? true : false, isRightPlaced: accept && ringOpacity === 1 ? true : false, stripe: selectedStripe }
    //     // }

    //     if ((droppedCoords && droppedCoords.length > 0)) {

    //         let horizontalStripe = -1
    //         let verticleStripe = -1

    //         const currentCoord = droppedCoords?.filter((item) => {
    //             return JSON.parse(item)[1] === indexArr
    //         })

    //         if (currentCoord.length > 0) {
    //             console.log('Current Coords', currentCoord)

    //         }

    //         if (currentCoord.length > 0) {
    //             if (JSON.parse(currentCoord[0])[0] === 1) {
    //                 horizontalStripe = 1
    //             } else {
    //                 verticleStripe = 0
    //             }

    //             if (currentCoord.length > 1) {
    //                 if (JSON.parse(currentCoord[1])[0] === 1) {
    //                     horizontalStripe = 1

    //                 } else {
    //                     verticleStripe = 0
    //                 }
    //             }
    //         }

    //         let horizontallyRightPlaced = false
    //         let verticallyRightPlaced = false
    //         const accept = acceptedCoords.filter(item => item === currentCoord[0] || (currentCoord.length > 1 && item === currentCoord[1]))



    //         if (accept.length > 0) {
    //             if (JSON.parse(accept[0])[0] === 1) {
    //                 horizontallyRightPlaced = true
    //             } else {
    //                 verticallyRightPlaced = true
    //             }

    //             if (accept.length > 1) {
    //                 if (JSON.parse(accept[1])[0] === 1) {
    //                     horizontallyRightPlaced = true

    //                 } else {
    //                     verticallyRightPlaced = true
    //                 }
    //             }
    //         }

    //         // const nearestAccept = acceptedCoords.find(item => JSON.parse(item)[1] === nearestCoord)
    //         // if (nearestAccept || accept) {
    //         //     return { isAccepted: accept ? true : nearestAccept && ringOpacity === 1 ? true : false, isRightPlaced: accept ? true : nearestAccept && ringOpacity === 1 ? true : false, stripe: accept ? currentCoord ? JSON.parse(currentCoord)[0] : -1 : selectedStripe }
    //         // }

    //         // return { isAccepted: currentCoord.length > 0 ? true : false, isRightPlaced: accept.length > 0 ? true : false, horizontalStripe: horizontalStripe, verticleStripe: verticleStripe }
    //         return { isAccepted: currentCoord.length > 0 ? true : false, horizontallyRightPlaced, verticallyRightPlaced, horizontalStripe: horizontalStripe, verticleStripe: verticleStripe }
    //     }

    //     return { isAccepted: false, stripe: -1 }
    // }





    const nConstraint = (heightId !== 0 && heightId !== verticalLastIndex)




    const n2VerticalConstraint = ((heightId !== 0 && heightId < (verticalLastIndex - 2)) && (widthId > (isAlternate ? (n1LastIndex - 1) : (n1LastIndex)) && widthId < n2LastIndex))
    const n3VerticalConstraint = ((heightId !== 0 && heightId < (verticalLastIndex - 4)) && (widthId > (isAlternate ? (n2LastIndex - 1) : (n2LastIndex)) && widthId < n3LastIndex))

    const n2HorizontalConstraint = (nConstraint && (widthId > (isAlternate ? (n1LastIndex - 1) : n1LastIndex) && widthId < (n2LastIndex - 1)))
    const n3HorizontalConstraint = (nConstraint && (widthId > (isAlternate ? (n2LastIndex - 1) : (n2LastIndex)) && widthId < (n3LastIndex - 2)))


    const n1StripeConstraint = (form, stripe) => {
        return form === 1 ?
            stripe === 1 ? nConstraint && widthId < (n1LastIndex - 1) :
                (heightId !== 0 && heightId < (verticalLastIndex - 2)) && widthId < n1LastIndex
            : nConstraint && widthId < n1LastIndex
    }

    const n2StripeConstraint = (form, stripe) => {
        if (form === 1) {
            return stripe === 1 ?
                (nConstraint && (widthId > (isAlternate ? (n1LastIndex - 1) : n1LastIndex) && widthId < (n2LastIndex - 2)))
                :
                ((heightId !== 0 && heightId < (verticalLastIndex - 4)) && (widthId > (isAlternate ? (n1LastIndex - 1) : (n1LastIndex)) && widthId < n2LastIndex))
        }

        return stripe === 1 ? n2HorizontalConstraint : n2VerticalConstraint

    }

    const n3StripeConstraint = (form, stripe) => {
        if (form === 1) {
            return stripe === 1 ?
                (nConstraint && (widthId > (isAlternate ? (n2LastIndex - 1) : (n2LastIndex)) && widthId < (n3LastIndex - 3)))
                :
                ((heightId !== 0 && heightId < (verticalLastIndex - 6)) && (widthId > (isAlternate ? (n2LastIndex - 1) : (n2LastIndex)) && widthId < n3LastIndex))
        }

        return stripe === 1 ? n3HorizontalConstraint : n3VerticalConstraint

    }

    const n1RectangleConstraint = (form) => {
        return form === 1 ?
            nConstraint && widthId < (n1LastIndex - 1) &&
            (heightId !== 0 && heightId < (verticalLastIndex - 2)) && widthId < n1LastIndex && (!isAlternate ? widthId > 0 : widthId >= 0)
            : nConstraint && widthId < n1LastIndex && (!isAlternate ? widthId > 0 : widthId >= 0)
    }

    const n2RectangleConstraint = (form) => {
        if (form === 1) {
            return (nConstraint && (widthId > (isAlternate ? (n1LastIndex - 3) : n1LastIndex-2) && widthId < (n2LastIndex-1))) &&
                ((heightId > 2 && heightId < (verticalLastIndex - 2)) && (widthId > (isAlternate ? (n1LastIndex) : (n1LastIndex+1)) && widthId < n2LastIndex))
        }
        else if (form === -1) {
            return (nConstraint && (widthId > (isAlternate ? n1LastIndex : (n1LastIndex )) && (widthId ) < (n2LastIndex)))
            &&
            ((heightId >= 1 && (heightId + 1) < verticalLastIndex) && (widthId > (isAlternate ? (n1LastIndex - 2) : (n1LastIndex - 2)) && widthId < n2LastIndex))

         }
         
         return (nConstraint && (widthId > (isAlternate ? n1LastIndex : (n1LastIndex )) && (widthId ) < (n2LastIndex)))
            &&
            ((heightId > 1 && (heightId + 1) < verticalLastIndex) && (widthId > (isAlternate ? (n1LastIndex - 2) : (n1LastIndex - 2)) && widthId < (isAlternate ? (n2LastIndex-1) : n2LastIndex)))
    

    }

    const n3RectangleConstraint = (form) => {
        if (form === 1) {
            return (nConstraint && (widthId > (isAlternate ? (n2LastIndex + 1) : (n2LastIndex+3)) && widthId < (n3LastIndex-1)))
                &&
                ((heightId > 3 && heightId < (verticalLastIndex - 3)) && (widthId > (isAlternate ? (n2LastIndex-22) : (n2LastIndex -22)) && widthId < n3LastIndex-2))
        }

        else if (form === -1) {
           return (nConstraint && (widthId > (isAlternate ? n2LastIndex : (n2LastIndex +1)) && (widthId ) < (n3LastIndex )))
           &&
           ((heightId > 2 && (heightId + 2) < verticalLastIndex) && (widthId > (isAlternate ? (n2LastIndex - 2) : (n2LastIndex - 2)) && widthId < n3LastIndex))

        }
        
        return (nConstraint && (widthId > (isAlternate ? n2LastIndex : (n2LastIndex +1)) && (widthId - 2) < (n3LastIndex - 3)))
            &&
            ((heightId > 2 && (heightId + 2) < verticalLastIndex) && (widthId > (isAlternate ? (n2LastIndex - 2) : (n2LastIndex - 2)) && (widthId) < n3LastIndex))
   

    }

    const n1TriangleConstraint = (form) => {
        return form === 1 ?
            nConstraint && widthId < (n1LastIndex - 1) &&
            (heightId !== 0 && heightId < (verticalLastIndex - 2)) && widthId < n1LastIndex && (!isAlternate ? widthId > 0 : widthId >= 0)
            : nConstraint && widthId < n1LastIndex && (!isAlternate ? widthId > 0 : widthId >= 0)
    }

    const n2TriangleConstraint = (form) => {
        if (form === 1) {
            return (nConstraint && (widthId > (isAlternate ? (n1LastIndex) : n1LastIndex + 1) && widthId < (n2LastIndex - 1))) &&
                ((heightId > 2 && heightId < (verticalLastIndex -2)) && (widthId > (isAlternate ? (n1LastIndex - 2) : (n1LastIndex)) && widthId < n2LastIndex))
        }
        else if (form === -1) {
            return  (nConstraint && (widthId > (isAlternate ? (n1LastIndex) : n1LastIndex) && widthId < (n2LastIndex ))) &&
            ((heightId > 1 && heightId < (verticalLastIndex + 1)) && (widthId > (isAlternate ? (n1LastIndex -3) : (n1LastIndex-4)) && widthId < n2LastIndex))
        }
        return  (nConstraint && (widthId > (isAlternate ? (n1LastIndex) : n1LastIndex) && widthId < (n2LastIndex ))) &&
            ((heightId > 1 && heightId < (verticalLastIndex-1)) && (widthId > (isAlternate ? (n1LastIndex -3) : (n1LastIndex-4)) && widthId < n2LastIndex-1))
        }

    const n3TriangleConstraint = (form) => {
        
        if (form === 1) {
            return (nConstraint && (widthId > (isAlternate ? (n2LastIndex + 1) : (n2LastIndex+3)) && widthId < (n3LastIndex-1)))
                &&
                ((heightId !== 0 && heightId < (verticalLastIndex - 4)) && (widthId > (isAlternate ? (n2LastIndex - 1) : (n2LastIndex)) && widthId < n3LastIndex))
        }

        else if (form === -1) {
           return (nConstraint && (widthId > (isAlternate ? n2LastIndex : (n2LastIndex +1 )) && widthId < (n3LastIndex )))
                &&
                ((heightId > 2 && (heightId + 2) < verticalLastIndex + 1) && (widthId > (isAlternate ? (n2LastIndex - 1) : (n2LastIndex - 1)) && (widthId) < n3LastIndex))

        }
        
        return (nConstraint && (widthId > (isAlternate ? n2LastIndex : (n2LastIndex +1)) && (widthId - 2) < (n3LastIndex - 3)))
            &&
            ((heightId > 2 && (heightId + 2) < verticalLastIndex) && (widthId > (isAlternate ? (n2LastIndex - 2) : (n2LastIndex - 2)) && (widthId) < n3LastIndex))
    }

    const n1Triangle2Constraint = (form) => {
        return form === 1 ?
            nConstraint && widthId < (isAlternate ? n1LastIndex - 1 : n1LastIndex) && widthId > 0 &&
            (heightId !== 0 && heightId < (verticalLastIndex - 1)) && widthId < n1LastIndex && (!isAlternate ? widthId > 0 : widthId >= 0)
            : nConstraint && widthId < n1LastIndex && (!isAlternate ? widthId > 1 : widthId >= 1)
    }

    const n2Triangle2Constraint = (form) => {
        if (form === 1) {
            return ((heightId !== 0 && heightId < (verticalLastIndex - 2)) && (widthId > (isAlternate ? n1LastIndex : n1LastIndex + 1) && widthId < n2LastIndex - 1))
        }
        return ((heightId !== 0 && heightId < (verticalLastIndex - 1)) && (widthId > (n1LastIndex) && widthId < (isAlternate ? n2LastIndex - 1 : n2LastIndex)))
    }

    const n3Triangle2Constraint = (form) => {
        if (form === 1) {
            return ((heightId !== 0 && heightId < (verticalLastIndex - 3)) && (widthId > (n2LastIndex + 1) && widthId < (isAlternate ? n3LastIndex - 2 : n3LastIndex - 1)))
        }

        return ((heightId !== 0 && heightId < (verticalLastIndex - 2)) && (widthId > (isAlternate ? n2LastIndex : n2LastIndex + 1) && widthId < (n3LastIndex - 1)))
    }

    const n1Triangle3Constraint = (form) => {
        return form === 1 ?
            nConstraint && widthId < (isAlternate ? n1LastIndex - 1 : n1LastIndex) &&
            (heightId !== 0 && heightId < (verticalLastIndex - 2)) && (!isAlternate ? widthId > 0 : widthId >= 0)
            : nConstraint && widthId < n1LastIndex && (!isAlternate ? widthId > 0 : widthId >= 0)
    }

    const n2Triangle3Constraint = (form) => {
        if (form === 1) {
            return ((heightId !== 0 && heightId < (verticalLastIndex - 2)) && (widthId > (isAlternate ? n1LastIndex : n1LastIndex + 1) && widthId < n2LastIndex - 1))
        }
        return ((heightId !== 0 && heightId < (verticalLastIndex - 1)) && (widthId > (n1LastIndex) && widthId < (isAlternate ? n2LastIndex - 1 : n2LastIndex)))

    }

    const n3Triangle3Constraint = (form) => {
        if (form === 1) {
            return ((heightId !== 0 && heightId < (verticalLastIndex - 3)) && (widthId > (n2LastIndex + 1) && widthId < (isAlternate ? n3LastIndex - 2 : n3LastIndex - 1)))
        }

        return ((heightId !== 0 && heightId < (verticalLastIndex - 2)) && (widthId > (isAlternate ? n2LastIndex : n2LastIndex + 1) && widthId < (n3LastIndex - 1)))
    }


    return <div draggable={false} ref={ref} className="relative">

        {(nearestCoord.length > 0 && ringOpacity === 1  ? [...currentCoords, JSON.stringify(nearestCoord)] : currentCoords)?.map((item, index) => {

            const isAccepted = acceptedCoords.find(i => i === item)
            const parsedItem = JSON.parse(item)
            const form = parsedItem[2]
            const stripe = parsedItem[0]
            var shape = parsedItem[3]


            const props = {
                key: index,
                isAccepted: isAccepted,
                containerWidth: containerWidth,
                horizontalLastIndex: horizontalLastIndex,
                selectedForm: form,
                selectedStripe: stripe,
                onDragStart: () => { onDragStart(item) },
                draggable: true,
                containerHeight: containerHeight,
                centerVerticalIndex: centerVerticalIndex,
                isShapeShake: isShapeShake,
                style: { opacity: (draggedElementCoords === item && draggedElementCoords !== JSON.stringify(nearestCoord)) ? 0.4 : 1 }
            }
            
            // if(form)
            if (shape === 'stripe') {
                return <>
                    {n1StripeConstraint(form, stripe) && <FirstContainerStripe key={index} draggable {...props} />}
                    {n2StripeConstraint(form, stripe) && <SecondContainerStripe key={index} draggable {...props} />}
                    {n3StripeConstraint(form, stripe) && <ThirdContainerStripe key={index} draggable {...props} />}

                </>
            }
            else if (shape === 'rectangle') {
                return <>
                    {n1RectangleConstraint(form, stripe) && <FirstContainerRectangle key={index} draggable {...props} />}
                    {n2RectangleConstraint(form, stripe) && <SecondContainerRectangle key={index} draggable {...props} />}
                    {n3RectangleConstraint(form, stripe) && <ThirdContainerRectangle key={index} draggable {...props} />}
                </>
            }
            else if (shape === 'triangle1') {
                return <>
                    {n1TriangleConstraint(form, stripe) && <FirstContainerTriangle1 key={index} draggable {...props} />}
                    {n2TriangleConstraint(form, stripe) && <SecondContainerTriangle1 key={index} draggable {...props} />}
                    {n3TriangleConstraint(form, stripe) && <ThirdContainerTriangle1 key={index} draggable {...props} />}
                </>
            }
            else if (shape === 'triangle2') {
                return <>
                    {n1Triangle2Constraint(form, stripe) && <FirstContainerTriangle2 key={index} draggable {...props} />}
                    {n2Triangle2Constraint(form, stripe) && <SecondContainerTriangle2 key={index} draggable {...props} />}
                    {n3Triangle2Constraint(form, stripe) && <ThirdContainerTriangle2 key={index} draggable {...props} />}
                </>
            }
            else if (shape === 'triangle3') {
                return <>
                    {n1Triangle3Constraint(form, stripe) && <FirstContainerTriangle3 key={index} draggable {...props} />}
                    {n2Triangle3Constraint(form, stripe) && <SecondContainerTriangle3 key={index} draggable {...props} />}
                    {n3Triangle3Constraint(form, stripe) && <ThirdContainerTriangle3 key={index} draggable {...props} />}
                </>
            }
            else if (shape === 'triangle4') {
                return <>
                    {n1Triangle3Constraint(form, stripe) && <FirstContainerTriangle4 key={index} draggable {...props} />}
                    {n2Triangle3Constraint(form, stripe) && <SecondContainerTriangle4 key={index} draggable {...props} />}
                    {n3Triangle3Constraint(form, stripe) && <ThirdContainerTriangle4 key={index} draggable {...props} />}
                </>
            }
            return <React.Fragment key={index}></React.Fragment>
        })}


        {/* {nConstraint && widthId < n1LastIndex &&
            <FirstContainerStripe containerHeight={containerHeight} centerVerticalIndex={centerVerticalIndex} containerWidth={containerWidth} horizontalLastIndex={horizontalLastIndex} selectedForm={selectedForm} selectedStripe={selectedStripe} horizontalAccept={horizontalAccept} verticalAccept={verticalAccept} onDragStart={() => { onDragStart(currentCoord) }} draggable style={{ opacity: horAccepted || verAccepted ? 1 : ringOpacity }} />
            // <div onDragStart={() => { onDragStart(currentCoord) }} draggable style={{ opacity: horAccepted || verAccepted ? 1 : ringOpacity }} className={`absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] h-[14px] w-[14px] rounded-[7px] border-2  ${horizontalAccept || verticalAccept ? 'border-teal-600' : 'border-red-600'}`}></div>
        }
        <>
            {(n2HorizontalConstraint) && <div onDragStart={() => { onDragStart(currentCoord) }} draggable style={{ opacity: horAccepted ? 1 : selectedStripe === 1 && ringOpacity === 1 ? 1 : 0, width: (containerWidth / horizontalLastIndex) + 14 }} className={`absolute top-[-60%] left-[-60%]  translate-x-[-50%  h-[14px] rounded-[7px] border-2 ${accept.horizontallyRightPlaced ? 'border-teal-600' : 'border-red-600'}`}></div>}
            {(n3HorizontalConstraint) && <div onDragStart={() => { onDragStart(currentCoord) }} draggable style={{ opacity: horAccepted ? 1 : selectedStripe === 1 && ringOpacity === 1 ? 1 : 0, width: ((containerWidth / horizontalLastIndex) + 7) * 2 }} className={`absolute top-[-60%] left-[-60%]  translate-x-[-50%  h-[14px] rounded-[7px] border-2  ${accept.horizontallyRightPlaced ? 'border-teal-600' : 'border-red-600'}`}></div>}
        </>

        <>
            {(n2VerticalConstraint) && <div onDragStart={() => { onDragStart(currentCoord) }} draggable style={{ opacity: verAccepted ? 1 : selectedStripe === 0 && ringOpacity === 1 ? 1 : 0, height: (containerHeight / centerVerticalIndex) + 13 }} className={`absolute top-[-60%] left-[-65%]  translate-x-[-50%  w-[14px] rounded-[7px] border-2  ${accept.verticallyRightPlaced ? 'border-teal-600' : 'border-red-600'}`}></div>}
            {(n3VerticalConstraint) && <div onDragStart={() => { onDragStart(currentCoord) }} draggable style={{ opacity: verAccepted ? 1 : selectedStripe === 0 && ringOpacity === 1 ? 1 : 0, height: ((containerHeight / centerVerticalIndex) + 6) * 2 }} className={`absolute top-[-60%] left-[-65%]  translate-x-[-50%  w-[14px] rounded-[7px] border-2  ${accept.verticallyRightPlaced ? 'border-teal-600' : 'border-red-600'}`}></div>}
        </> */}

        <div className={className}>
            {/*<p className="text-sm">{indexArr}</p>*/}
        </div>
    </div>




    // return <div draggable={false} ref={ref} className="relative">
    //     {nConstraint && widthId < n1LastIndex && <div onDragStart={() => { onDragStart(currentCoord) }} draggable style={{ opacity: horAccepted || verAccepted ? 1 : ringOpacity }} className={`absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] h-[14px] w-[14px] rounded-[7px] border-2  ${horizontalAccept || verticalAccept ? 'border-teal-600' : 'border-red-600'}`}></div>}
    //     <>
    //         {(n2HorizontalConstraint) && <div onDragStart={() => { onDragStart(currentCoord) }} draggable style={{ opacity: horAccepted ? 1 : selectedStripe === 1 && ringOpacity === 1 ? 1 : 0, width: (containerWidth / horizontalLastIndex) + 14 }} className={`absolute top-[-60%] left-[-60%]  translate-x-[-50%  h-[14px] rounded-[7px] border-2 ${accept.horizontallyRightPlaced ? 'border-teal-600' : 'border-red-600'}`}></div>}
    //         {(n3HorizontalConstraint) && <div onDragStart={() => { onDragStart(currentCoord) }} draggable style={{ opacity: horAccepted ? 1 : selectedStripe === 1 && ringOpacity === 1 ? 1 : 0, width: ((containerWidth / horizontalLastIndex) + 7) * 2 }} className={`absolute top-[-60%] left-[-60%]  translate-x-[-50%  h-[14px] rounded-[7px] border-2  ${accept.horizontallyRightPlaced ? 'border-teal-600' : 'border-red-600'}`}></div>}
    //     </>

    //     <>
    //         {(n2VerticalConstraint) && <div onDragStart={() => { onDragStart(currentCoord) }} draggable style={{ opacity: verAccepted ? 1 : selectedStripe === 0 && ringOpacity === 1 ? 1 : 0, height: (containerHeight / centerVerticalIndex) + 13 }} className={`absolute top-[-60%] left-[-65%]  translate-x-[-50%  w-[14px] rounded-[7px] border-2  ${accept.verticallyRightPlaced ? 'border-teal-600' : 'border-red-600'}`}></div>}
    //         {(n3VerticalConstraint) && <div onDragStart={() => { onDragStart(currentCoord) }} draggable style={{ opacity: verAccepted ? 1 : selectedStripe === 0 && ringOpacity === 1 ? 1 : 0, height: ((containerHeight / centerVerticalIndex) + 6) * 2 }} className={`absolute top-[-60%] left-[-65%]  translate-x-[-50%  w-[14px] rounded-[7px] border-2  ${accept.verticallyRightPlaced ? 'border-teal-600' : 'border-red-600'}`}></div>}
    //     </>

    //     <div className={className}></div>
    // </div>

    // return <>
    //     <div ref={ref} className={className}></div>

    //     <div className="relative">
    //         {/* {<div style={{ opacity: ringOpacity }} className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] h-[14px] w-[14px] rounded-[7px] border-2 border-teal-600"></div>} */}
    //         {/* {widthId < 5 && <div style={{ opacity: ringOpacity }} className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] h-[14px] w-[14px] rounded-[7px] border-2 border-teal-600"></div>} */}
    //         {widthId > 5 && widthId < 11 && <div style={{ opacity: ringOpacity }} className="absolute top-[-50%] left-[-50%]  translate-x-[-50%  h-[14px] w-[74px] rounded-[7px] border-2 border-teal-600"></div>}
    //     </div>
    // </>

}

