
function stringify(val) {
    return JSON.stringify(val)
}

const horizontalStripe = 1
const verticalStripe = 0



// const verticalLength = 15
// const verticalLastIndex = verticalLength - 1

// const centerVerticalIndex = verticalLastIndex / 2
const n1CenterIndex = 2
const n2CenterIndex = 8
const n3CenterIndex = 14



const n1 = [
    stringify([7, 2]),
    stringify([8, 2]),
    stringify([8, 3]),
    stringify([10, 2]),
    stringify([10, 3]),
]

const n2 = [

    stringify([4, 8]),
    stringify([5, 7]),
    stringify([5, 8]),
    stringify([6, 7]),
    stringify([6, 8]),
    stringify([6, 9]),
    stringify([8, 7]),
    stringify([8, 8]),
    stringify([8, 9]),
    stringify([10, 7]),
    stringify([10, 8]),
    stringify([10, 9]),


]

const n3 = [

    stringify([3, 14]),
    stringify([4, 14]),
    stringify([4, 15]),
    stringify([5, 13]),
    stringify([5, 14]),
    stringify([5, 15]),
    stringify([6, 13]),
    stringify([6, 14]),
    stringify([6, 15]),
    stringify([6, 16]),

    stringify([8, 13]),
    stringify([8, 14]),
    stringify([8, 15]),
    stringify([8, 16]),


    stringify([10, 13]),
    stringify([10, 14]),
    stringify([10, 15]),
    stringify([10, 16]),

    stringify([12, 13]),
    stringify([12, 14]),
    stringify([12, 15]),
    stringify([12, 16]),



]

const acceptedCoords = [

    //n1 rectangle
    stringify([verticalStripe, n1[0], 0, 'rectangle']),
    stringify([verticalStripe, n1[1], 0, 'rectangle']),
    stringify([verticalStripe, n1[2], 0, 'rectangle']),
    stringify([verticalStripe, n1[3], 0, 'rectangle']),
    stringify([verticalStripe, n1[4], 0, 'rectangle']),

    stringify([verticalStripe, n1[0], -1, 'rectangle']),
    stringify([verticalStripe, n1[1], -1, 'rectangle']),
    stringify([verticalStripe, n1[2], -1, 'rectangle']),
    stringify([verticalStripe, n1[3], -1, 'rectangle']),
    stringify([verticalStripe, n1[4], -1, 'rectangle']),

    stringify([verticalStripe, n1[1], 1, 'rectangle']),

    //n1 triangle

    stringify([verticalStripe, n1[0], 0, 'triangle2']),
    stringify([verticalStripe, n1[1], 0, 'triangle2']),
    stringify([verticalStripe, n1[2], 0, 'triangle2']),
    stringify([verticalStripe, n1[3], 0, 'triangle2']),
    stringify([verticalStripe, n1[4], 0, 'triangle2']),

    stringify([verticalStripe, n1[0], -1, 'triangle2']),
    stringify([verticalStripe, n1[1], -1, 'triangle2']),
    stringify([verticalStripe, n1[2], -1, 'triangle2']),
    stringify([verticalStripe, n1[3], -1, 'triangle2']),
    stringify([verticalStripe, n1[4], -1, 'triangle2']),

    stringify([verticalStripe, n1[0], 1, 'triangle2']),



    //verticle stripe n1
    stringify([verticalStripe, n1[0], 0, 'stripe']),
    stringify([verticalStripe, n1[1], 0, 'stripe']),
    stringify([verticalStripe, n1[2], 0, 'stripe']),
    stringify([verticalStripe, n1[3], 0, 'stripe']),
    stringify([verticalStripe, n1[4], 0, 'stripe']),

    stringify([verticalStripe, n1[0], -1, 'stripe']),
    stringify([verticalStripe, n1[1], -1, 'stripe']),
    stringify([verticalStripe, n1[2], -1, 'stripe']),
    stringify([verticalStripe, n1[3], -1, 'stripe']),
    stringify([verticalStripe, n1[4], -1, 'stripe']),

    stringify([verticalStripe, n1[1], 1, 'stripe']),
    stringify([verticalStripe, n1[2], 1, 'stripe']),


    //Horizontal stripe n1
    stringify([horizontalStripe, n1[0], 0, 'stripe']),
    stringify([horizontalStripe, n1[1], 0, 'stripe']),
    stringify([horizontalStripe, n1[2], 0, 'stripe']),
    stringify([horizontalStripe, n1[3], 0, 'stripe']),
    stringify([horizontalStripe, n1[4], 0, 'stripe']),

    stringify([horizontalStripe, n1[0], -1, 'stripe']),
    stringify([horizontalStripe, n1[1], -1, 'stripe']),
    stringify([horizontalStripe, n1[2], -1, 'stripe']),
    stringify([horizontalStripe, n1[3], -1, 'stripe']),
    stringify([horizontalStripe, n1[4], -1, 'stripe']),

    stringify([horizontalStripe, n1[1], 1, 'stripe']),
    stringify([horizontalStripe, n1[3], 1, 'stripe']),


    //n2 rectangle

    stringify([verticalStripe, stringify([5,7]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,7]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([9,7]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([4,8]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([3,8]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([4,9]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,8]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,8]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([9,8]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,9]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,9]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([9,9]), -1, 'rectangle']),
   

    stringify([verticalStripe, stringify([9,7]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7,7]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7,8]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([9,8]), 0, 'rectangle']),


    stringify([verticalStripe, stringify([8,8]), 1, 'rectangle']),

    //n2 triangle


    stringify([verticalStripe, n2[0], 0, 'triangle2']),
    stringify([verticalStripe, n2[1], 0, 'triangle2']),
    stringify([verticalStripe, n2[2], 0, 'triangle2']),

    stringify([verticalStripe, n2[0], -1, 'triangle2']),
    stringify([verticalStripe, n2[1], -1, 'triangle2']),
    stringify([verticalStripe, n2[2], -1, 'triangle2']),
    stringify([verticalStripe, n2[3], -1, 'triangle2']),
    stringify([verticalStripe, n2[4], -1, 'triangle2']),
    stringify([verticalStripe, n2[5], -1, 'triangle2']),
    stringify([verticalStripe, n2[6], -1, 'triangle2']),
    stringify([verticalStripe, n2[7], -1, 'triangle2']),
    stringify([verticalStripe, n2[8], -1, 'triangle2']),
    stringify([verticalStripe, n2[9], -1, 'triangle2']),
    stringify([verticalStripe, n2[10], -1, 'triangle2']),
    stringify([verticalStripe, n2[11], -1, 'triangle2']),

    stringify([verticalStripe, n2[0], 1, 'triangle2']),


    //verticle stripe n2
    stringify([verticalStripe, n2[0], 0, 'stripe']),
    stringify([verticalStripe, n2[3], 0, 'stripe']),
    stringify([verticalStripe, n2[4], 0, 'stripe']),
    stringify([verticalStripe, n2[5], 0, 'stripe']),
    stringify([verticalStripe, n2[6], 0, 'stripe']),
    stringify([verticalStripe, n2[7], 0, 'stripe']),
    stringify([verticalStripe, n2[8], 0, 'stripe']),

    stringify([verticalStripe, n2[0], -1, 'stripe']),
    stringify([verticalStripe, n2[3], -1, 'stripe']),
    stringify([verticalStripe, n2[4], -1, 'stripe']),
    stringify([verticalStripe, n2[5], -1, 'stripe']),
    stringify([verticalStripe, n2[6], -1, 'stripe']),
    stringify([verticalStripe, n2[7], -1, 'stripe']),
    stringify([verticalStripe, n2[8], -1, 'stripe']),
    stringify([verticalStripe, stringify([4,7]), -1, 'stripe']),
    stringify([verticalStripe, stringify([3,7]), -1, 'stripe']),
    stringify([verticalStripe, stringify([2,8]), -1, 'stripe']),
    stringify([verticalStripe, stringify([3,8]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,9]), -1, 'stripe']),


    stringify([verticalStripe, n2[0], 1, 'stripe']),
    stringify([verticalStripe, n2[3], 1, 'stripe']),
    stringify([verticalStripe, n2[4], 1, 'stripe']),
    stringify([verticalStripe, n2[5], 1, 'stripe']),

    //horizontal stripe n2
    stringify([horizontalStripe, n2[1], 0, 'stripe']),
    stringify([horizontalStripe, n2[3], 0, 'stripe']),
    stringify([horizontalStripe, n2[4], 0, 'stripe']),
    stringify([horizontalStripe, n2[6], 0, 'stripe']),
    stringify([horizontalStripe, n2[7], 0, 'stripe']),
    stringify([horizontalStripe, n2[9], 0, 'stripe']),
    stringify([horizontalStripe, n2[10], 0, 'stripe']),

    stringify([horizontalStripe, n2[0], -1, 'stripe']),
    stringify([horizontalStripe, n2[1], -1, 'stripe']),
    stringify([horizontalStripe, n2[2], -1, 'stripe']),
    stringify([horizontalStripe, n2[3], -1, 'stripe']),
    stringify([horizontalStripe, n2[4], -1, 'stripe']),
    stringify([horizontalStripe, n2[5], -1, 'stripe']),
    stringify([horizontalStripe, n2[6], -1, 'stripe']),
    stringify([horizontalStripe, n2[7], -1, 'stripe']),
    stringify([horizontalStripe, n2[8], -1, 'stripe']),
    stringify([horizontalStripe, n2[9], -1, 'stripe']),
    stringify([horizontalStripe, n2[10], -1, 'stripe']),
    stringify([horizontalStripe, n2[11], -1, 'stripe']),

    stringify([horizontalStripe, n2[3], 1, 'stripe']),
    stringify([horizontalStripe, n2[6], 1, 'stripe']),
    stringify([horizontalStripe, n2[9], 1, 'stripe']),



    //n3 rectangle
    stringify([verticalStripe, n3[6], -1, 'rectangle']),
    stringify([verticalStripe, n3[7], -1, 'rectangle']),
    stringify([verticalStripe, n3[10], -1, 'rectangle']),
    stringify([verticalStripe, n3[11], -1, 'rectangle']),
    stringify([verticalStripe, stringify([10,14]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([8,14]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([6,14]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([10,15]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([8,15]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([6,15]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([10,16]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([8,16]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([6,16]), -1, 'rectangle']),

    stringify([verticalStripe, stringify([10, 14]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([8, 14]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([10, 15]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([8, 15]), 0, 'rectangle']),
    stringify([verticalStripe, n3[7], 0, 'rectangle']),
    stringify([verticalStripe, n3[10], 0, 'rectangle']),
    stringify([verticalStripe, n3[11], 0, 'rectangle']),


    stringify([verticalStripe, stringify([9, 14]), 1, 'rectangle']),

    //n3 triangle
    stringify([verticalStripe, n3[0], 0, 'triangle2']),
    stringify([verticalStripe, n3[1], 0, 'triangle2']),
    stringify([verticalStripe, n3[2], 0, 'triangle2']),
    stringify([verticalStripe, n3[7], 0, 'triangle2']),
    stringify([verticalStripe, n3[8], 0, 'triangle2']),
    stringify([verticalStripe, n3[11], 0, 'triangle2']),
    stringify([verticalStripe, n3[12], 0, 'triangle2']),
    stringify([verticalStripe, n3[15], 0, 'triangle2']),
    stringify([verticalStripe, n3[16], 0, 'triangle2']),

    stringify([verticalStripe, n3[0], -1, 'triangle2']),
    stringify([verticalStripe, n3[1], -1, 'triangle2']),
    stringify([verticalStripe, n3[2], -1, 'triangle2']),
    stringify([verticalStripe, n3[7], -1, 'triangle2']),
    stringify([verticalStripe, n3[8], -1, 'triangle2']),
    stringify([verticalStripe, n3[11], -1, 'triangle2']),
    stringify([verticalStripe, n3[12], -1, 'triangle2']),
    stringify([verticalStripe, n3[15], -1, 'triangle2']),
    stringify([verticalStripe, n3[16], -1, 'triangle2']),

    stringify([verticalStripe, n3[0], 1, 'triangle2']),


    //verticle stripe n3
    stringify([verticalStripe, n3[1], 0, 'stripe']),
    stringify([verticalStripe, n3[2], 0, 'stripe']),
    stringify([verticalStripe, n3[6], 0, 'stripe']),
    stringify([verticalStripe, n3[7], 0, 'stripe']),
    stringify([verticalStripe, n3[8], 0, 'stripe']),
    stringify([verticalStripe, n3[9], 0, 'stripe']),
    stringify([verticalStripe, n3[10], 0, 'stripe']),
    stringify([verticalStripe, n3[11], 0, 'stripe']),
    stringify([verticalStripe, n3[12], 0, 'stripe']),
    stringify([verticalStripe, n3[13], 0, 'stripe']),

    stringify([verticalStripe, n3[1], -1, 'stripe']),
    stringify([verticalStripe, n3[2], -1, 'stripe']),
    stringify([verticalStripe, n3[6], -1, 'stripe']),
    stringify([verticalStripe, n3[7], -1, 'stripe']),
    stringify([verticalStripe, n3[8], -1, 'stripe']),
    stringify([verticalStripe, n3[9], -1, 'stripe']),
    stringify([verticalStripe, n3[10], -1, 'stripe']),
    stringify([verticalStripe, n3[11], -1, 'stripe']),
    stringify([verticalStripe, n3[12], -1, 'stripe']),
    stringify([verticalStripe, n3[13], -1, 'stripe']),
    stringify([verticalStripe, stringify([4,13]), -1, 'stripe']),
    stringify([verticalStripe, stringify([1,14]), -1, 'stripe']),
    stringify([verticalStripe, stringify([12,15]), -1, 'stripe']),
    stringify([verticalStripe, stringify([12,14]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([12,15]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([10,15]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([8,15]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([6,15]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([5,14]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([4,14]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,16]), -1, 'stripe']),
    stringify([verticalStripe, stringify([2,14]), -1, 'stripe']),
    stringify([verticalStripe, stringify([2,15]), -1, 'stripe']),

    stringify([verticalStripe, n3[1], 1, 'stripe']),
    stringify([verticalStripe, n3[2], 1, 'stripe']),
    stringify([verticalStripe, n3[6], 1, 'stripe']),
    stringify([verticalStripe, n3[7], 1, 'stripe']),
    stringify([verticalStripe, n3[8], 1, 'stripe']),
    stringify([verticalStripe, n3[9], 1, 'stripe']),

    //horizontal stripe n3
    stringify([horizontalStripe, n3[3], 0, 'stripe']),
    stringify([horizontalStripe, n3[6], 0, 'stripe']),
    stringify([horizontalStripe, n3[7], 0, 'stripe']),
    stringify([horizontalStripe, n3[10], 0, 'stripe']),
    stringify([horizontalStripe, n3[11], 0, 'stripe']),
    stringify([horizontalStripe, n3[14], 0, 'stripe']),
    stringify([horizontalStripe, n3[15], 0, 'stripe']),
    stringify([horizontalStripe, n3[18], 0, 'stripe']),
    stringify([horizontalStripe, n3[19], 0, 'stripe']),

    stringify([horizontalStripe, n3[3], -1, 'stripe']),
    stringify([horizontalStripe, n3[6], -1, 'stripe']),
    stringify([horizontalStripe, n3[7], -1, 'stripe']),
    stringify([horizontalStripe, n3[10], -1, 'stripe']),
    stringify([horizontalStripe, n3[11], -1, 'stripe']),
    stringify([horizontalStripe, n3[14], -1, 'stripe']),
    stringify([horizontalStripe, n3[15], -1, 'stripe']),
    stringify([horizontalStripe, n3[18], -1, 'stripe']),
    stringify([horizontalStripe, n3[19], -1, 'stripe']),


    stringify([horizontalStripe, n3[6], 1, 'stripe']),
    stringify([horizontalStripe, n3[10], 1, 'stripe']),
    stringify([horizontalStripe, n3[14], 1, 'stripe']),
    stringify([horizontalStripe, n3[18], 1, 'stripe']),




]

export const hustallene = {

    n1: n1,
    n2: n2,
    n3: n3,
    acceptedCoords: acceptedCoords,

    n1CenterIndex: n1CenterIndex,
    n2CenterIndex: n2CenterIndex,
    n3CenterIndex: n3CenterIndex,

    shape: [['rectangle', 0], ['triangle2', 1], ['stripe', 0], ['stripe', 1]],

    name: 'Hustallene',
    formula: 'n ^ 2 * 3 / 2 + n * 5 / 2 + 1',



}