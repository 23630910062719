
function stringify(val) {
    return JSON.stringify(val)
}

const horizontalStripe = 1
const verticalStripe = 0



// const verticalLength = 15
// const verticalLastIndex = verticalLength - 1

// const centerVerticalIndex = verticalLastIndex / 2
const n1CenterIndex = 2
const n2CenterIndex = 8
const n3CenterIndex = 14



const n1 = [
    stringify([7, 1]),
    stringify([7, 3]),
    stringify([7, 2]),
    stringify([5, 2]),
    stringify([9, 2]),
]

const n2 = [
    stringify([3, 8]),
    stringify([9, 8]),
    stringify([5, 8]),
    stringify([7, 8]),
    stringify([7, 6]),
    stringify([7, 7]),
    stringify([7, 9]),
    stringify([7, 10]),
    stringify([11, 8]),
]

const n3 = [
    stringify([1, 14]),
    stringify([3, 14]),
    stringify([5, 14]),
    stringify([9, 14]),
    stringify([7, 14]),
    stringify([7, 11]),
    stringify([7, 12]),
    stringify([7, 13]),
    stringify([7, 15]),
    stringify([7, 16]),
    stringify([7, 17]),
    stringify([7, 18]),
    stringify([11, 14]),
    stringify([13, 14]),
]

const acceptedCoords = [
    stringify([horizontalStripe, n1[0], 0, 'stripe']),
    stringify([horizontalStripe, n1[1], 0, 'stripe']),
    stringify([horizontalStripe, n1[2], 0, 'stripe']),
    stringify([horizontalStripe, n1[3], 0, 'stripe']),
    stringify([horizontalStripe, n1[4], 0, 'stripe']),
    stringify([verticalStripe, n1[0], 0, 'stripe']),
    stringify([verticalStripe, n1[1], 0, 'stripe']),
    stringify([verticalStripe, n1[2], 0, 'stripe']),
    stringify([verticalStripe, n1[3], 0, 'stripe']),
    stringify([verticalStripe, n1[4], 0, 'stripe']),

    stringify([horizontalStripe, n1[0], -1, 'stripe']),
    stringify([horizontalStripe, n1[1], -1, 'stripe']),
    stringify([horizontalStripe, n1[2], -1, 'stripe']),
    stringify([horizontalStripe, n1[3], -1, 'stripe']),
    stringify([horizontalStripe, n1[4], -1, 'stripe']),
    stringify([verticalStripe, n1[0], -1, 'stripe']),
    stringify([verticalStripe, n1[1], -1, 'stripe']),
    stringify([verticalStripe, n1[2], -1, 'stripe']),
    stringify([verticalStripe, n1[3], -1, 'stripe']),
    stringify([verticalStripe, n1[4], -1, 'stripe']),


    stringify([horizontalStripe, n1[0], 1, 'stripe']),
    stringify([horizontalStripe, n1[2], 1, 'stripe']),
    stringify([verticalStripe, n1[2], 1, 'stripe']),
    stringify([verticalStripe, n1[3], 1, 'stripe']),

    // Second Container

    stringify([verticalStripe, n2[0], 0, 'stripe']),
    stringify([verticalStripe, n2[1], 0, 'stripe']),
    stringify([verticalStripe, n2[2], 0, 'stripe']),
    stringify([verticalStripe, n2[3], 0, 'stripe']),
    stringify([horizontalStripe, n2[3], 0, 'stripe']),
    stringify([horizontalStripe, n2[4], 0, 'stripe']),
    stringify([horizontalStripe, n2[5], 0, 'stripe']),
    stringify([horizontalStripe, n2[6], 0, 'stripe']),

    stringify([verticalStripe, n2[0], -1, 'stripe']),
    stringify([verticalStripe, n2[1], -1, 'stripe']),
    stringify([verticalStripe, n2[2], -1, 'stripe']),
    stringify([verticalStripe, n2[3], -1, 'stripe']),
    stringify([verticalStripe, stringify([1,8]), -1, 'stripe']),
    stringify([verticalStripe, stringify([5,6]), -1, 'stripe']),
    stringify([verticalStripe, stringify([5,7]), -1, 'stripe']),
    stringify([verticalStripe, stringify([5,8]), -1, 'stripe']),
    stringify([verticalStripe, stringify([5,9]), -1, 'stripe']),
    stringify([verticalStripe, stringify([5,10]), -1, 'stripe']),
    stringify([horizontalStripe, n2[3], -1, 'stripe']),
    stringify([horizontalStripe, n2[4], -1, 'stripe']),
    stringify([horizontalStripe, n2[5], -1, 'stripe']),
    stringify([horizontalStripe, n2[6], -1, 'stripe']),
    stringify([horizontalStripe, stringify([3,8]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([5,8]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([7,8]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([9,8]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([11,8]), -1, 'stripe']),

    stringify([verticalStripe, n2[0], 1, 'stripe']),
    stringify([verticalStripe, n2[2], 1, 'stripe']),
    stringify([verticalStripe, n2[3], 1, 'stripe']),
    stringify([horizontalStripe, n2[3], 1, 'stripe']),
    stringify([horizontalStripe, n2[4], 1, 'stripe']),
    stringify([horizontalStripe, n2[5], 1, 'stripe']),


    //Third Container

    stringify([verticalStripe, n3[0], 0, 'stripe']),
    stringify([verticalStripe, n3[1], 0, 'stripe']),
    stringify([verticalStripe, n3[2], 0, 'stripe']),
    stringify([verticalStripe, n3[3], 0, 'stripe']),
    stringify([verticalStripe, n3[4], 0, 'stripe']),
    stringify([horizontalStripe, n3[4], 0, 'stripe']),
    stringify([horizontalStripe, n3[5], 0, 'stripe']),
    stringify([horizontalStripe, n3[6], 0, 'stripe']),
    stringify([horizontalStripe, n3[7], 0, 'stripe']),
    stringify([horizontalStripe, n3[8], 0, 'stripe']),

    stringify([verticalStripe, n3[0], -1, 'stripe']),
    stringify([verticalStripe, n3[1], -1, 'stripe']),
    stringify([verticalStripe, n3[2], -1, 'stripe']),
    stringify([verticalStripe, n3[3], -1, 'stripe']),
    stringify([verticalStripe, n3[4], -1, 'stripe']),
    stringify([horizontalStripe, n3[4], -1, 'stripe']),
    stringify([horizontalStripe, n3[5], -1, 'stripe']),
    stringify([horizontalStripe, n3[6], -1, 'stripe']),
    stringify([horizontalStripe, n3[7], -1, 'stripe']),
    stringify([horizontalStripe, n3[8], -1, 'stripe']),

    stringify([verticalStripe, n3[0], 1, 'stripe']),
    stringify([verticalStripe, n3[1], 1, 'stripe']),
    stringify([verticalStripe, n3[2], 1, 'stripe']),
    stringify([verticalStripe, n3[4], 1, 'stripe']),
    stringify([horizontalStripe, n3[4], 1, 'stripe']),
    stringify([horizontalStripe, n3[5], 1, 'stripe']),
    stringify([horizontalStripe, n3[6], 1, 'stripe']),
    stringify([horizontalStripe, n3[7], 1, 'stripe']),


]

export const krysstallene = {

    n1: n1,
    n2: n2,
    n3: n3,
    acceptedCoords: acceptedCoords,

    n1CenterIndex: n1CenterIndex,
    n2CenterIndex: n2CenterIndex,
    n3CenterIndex: n3CenterIndex,

    shape: [['stripe', 0], ['stripe', 1]],

    name: 'Krysstallene',

    formula: '4 n + 1'




}