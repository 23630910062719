export const Triangle4Stripe = ({ selectedForm, ...props }) => {
    switch (selectedForm) {
        case -1:
            return <div {...props} className="relative">
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)", strokeDasharray: 2 }} points="49,1 1,25 49,49" class="triangle" />
                </svg>
                <svg style={{ position: 'absolute', top: 0 }} height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="40,5.5 1,25 40,44.5" class="triangle" />
                </svg>
            </div>
        case 0:
            return <div {...props}>
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="49,1 1,25 49,49" class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className="relative">
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="49,1 1,25 49,49" class="triangle" />
                </svg>
                <svg style={{ position: 'absolute', top: 0 }} height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="40,5.5 1,25 40,44.5" class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props}>
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="49,1 1,25 49,49" class="triangle" />
                </svg>
            </div>
    }
}

// Shapes to show on the dots
export const FirstContainerTriangle4 = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, ...props }) => {
    const width = ((containerWidth / horizontalLastIndex) + 15) + 10
    const height = ((containerHeight / centerVerticalIndex) + 13) + 15

    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute top-[60%] translate-y-[-16%] translate-x-[-10%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height * 0.16} ${width * 0.20},1  ${width * 0.20},${height * 0.32}`} class="triangle" />
                </svg>
            </div>
        case -1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-16%] translate-x-[-10%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)", strokeDasharray: 2 }} points={`1,${height * 0.16} ${width * 0.20},1  ${width * 0.20},${height * 0.32}`} class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-18%] translate-x-[-50%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height / 2} ${width / 1.65},1 ${width / 1.65},${height - 1}`} class="triangle" />
                </svg>
                <svg height={height} width={width} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height / 2} ${width / 2.5},${height * 0.18} ${width / 2.5},${height * 0.82}`} class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className={`absolute top-[60%] translate-y-[-16%] translate-x-[-10%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height * 0.16} ${width * 0.20},1  ${width * 0.20},${height * 0.32}`} class="triangle" />
                </svg>
            </div>

    }
}

export const SecondContainerTriangle4 = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, ...props }) => {
    const width = ((containerWidth / horizontalLastIndex) + 15) + 10
    const height = ((containerHeight / centerVerticalIndex) + 13) + 15
    console.log("Her er valgt form", selectedForm)

    const height1 = (((containerHeight / centerVerticalIndex) + 6) * 2) + 30
    const width1 = (((containerWidth / horizontalLastIndex) + 7) * 2) + 35
    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute top-[60%] translate-y-[-18%] translate-x-[-50%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height / 2} ${width / 1.65},1 ${width / 1.65},${height - 1}`} class="triangle" />
                </svg>
            </div>
        case -1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-15%] translate-x-[-43%]`}>
                <svg height={height1} width={width1}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)", strokeDasharray: 2  }} points={`1,${height1 * 0.505} ${width1 / 3},27 ${width1 / 3},${height1 - 26}`} class="triangle" />
                </svg>
                <svg height={height1} width={width1} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)"}} points={`1,${height1 * 0.505} ${width1 * 0.18},${height1 * 0.33} ${width1 * 0.18},${height1 * 0.678}`} class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-15%] translate-x-[-43%]`}>
                <svg height={height1} width={width1}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height1 * 0.5} ${width1 / 2},1 ${width1 / 2},${height1 - 1}`} class="triangle" />
                </svg>
                <svg height={height1} width={width1} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height1 * 0.5} ${width1 * 0.35},${height1 * 0.16} ${width1 * 0.35},${height1 * 0.84}`} class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className={`absolute top-[60%] translate-y-[-18%] translate-x-[-50%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height / 2} ${width / 1.65},1 ${width / 1.65},${height - 1}`} class="triangle" />
                </svg>
            </div>

    }
}

export const ThirdContainerTriangle4 = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, ...props }) => {

    const height = (((containerHeight / centerVerticalIndex) + 6) * 2) + 30
    const width = (((containerWidth / horizontalLastIndex) + 7) * 2) + 35

    const height1 = (((containerHeight / centerVerticalIndex) + 6) * 2.9) + 30
    const width1 = (((containerWidth / horizontalLastIndex) + 7) * 2.9)


    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute top-[60%] translate-y-[-15%] translate-x-[-43%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height * 0.5} ${width / 2},1 ${width / 2},${height - 1}`} class="triangle" />
                </svg>
            </div>
        case -1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-25%] translate-x-[-38%]`}>
                <svg height={height1} width={width1}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)", strokeDasharray: 2 }} points={`1,${height1 * 0.5} ${width1 * 0.48},${height1 * 0.105} ${width1 * 0.48},${height1 * 0.895}`} class="triangle" />
                </svg>
                <svg height={height1} width={width1} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)"}} points={`1,${height1 * 0.5} ${width1 * 0.3},${height1 * 0.256} ${width1 * 0.3},${height1 * 0.744}`} class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-10%] translate-x-[-53%]`}>
                <svg height={height1} width={width1}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height1 * 0.5} ${width1 * 0.6},1 ${width1 * 0.6},${height1 - 1}`} class="triangle" />
                </svg>
                <svg height={height1} width={width1} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height1 * 0.5} ${width1 * 0.48},${height1 * 0.105} ${width1 * 0.48},${height1 * 0.895}`} class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className={`absolute top-[60%] translate-y-[-20%] translate-x-[-48%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height - 1} ${width / 2},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
            </div>

    }
}

