export const Triangle3Stripe = ({ selectedForm, ...props }) => {
    switch (selectedForm) {
        case -1:
            return <div {...props} className="relative">
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)", strokeDasharray: 2 }} points="1,1 49,25 1,49" class="triangle" />
                </svg>
                <svg style={{ position: 'absolute', top: 0 }} height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="10,5.5 49,25 10,44.5" class="triangle" />
                </svg>
            </div>
        case 0:
            return <div {...props}>
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="1,1 49,25 1,49" class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className="relative">
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="1,1 49,25 1,49" class="triangle" />
                </svg>
                <svg style={{ position: 'absolute', top: 0 }} height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="10,5.5 49,25 10,44.5" class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props}>
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="1,1 49,25 1,49" class="triangle" />
                </svg>
            </div>
    }
}

// Shapes to show on the dots
export const FirstContainerTriangle3 = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, ...props }) => {
    const width = ((containerWidth / horizontalLastIndex) + 15) + 10
    const height = ((containerHeight / centerVerticalIndex) + 13) + 15

    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute top-[60%] translate-y-[-18%] translate-x-[-4%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width * 0.18},${height * 0.18} 1,${height * 0.32}`} class="triangle" />
                </svg>
            </div>
        case -1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-18%] translate-x-[-4%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)", strokeDasharray: 2 }} points={`1,1 ${width * 0.18},${height * 0.18} 1,${height * 0.32}`} class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-18%] translate-x-[-4%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width / 1.7},${height / 2} 1,${height - 1}`} class="triangle" />
                </svg>
                <svg height={height} width={width} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`${width * 0.21},${height * 0.18} ${width / 1.7},${height / 2} ${width * 0.22},${height * 0.8}`} class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className={`absolute top-[60%] translate-y-[-18%] translate-x-[-4%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width * 0.18},${height * 0.18} 1,${height * 0.32}`} class="triangle" />
                </svg>
            </div>

    }
}

export const SecondContainerTriangle3 = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, ...props }) => {
    const width = ((containerWidth / centerVerticalIndex) + 15) + 10
    const height = ((containerHeight / centerVerticalIndex) + 13) + 15


    const height1 = (((containerHeight / centerVerticalIndex) + 6) * 2) + 20
    const width1 = (((containerWidth / horizontalLastIndex) + 7) * 2) + 10
    console.log("Her er valgt form", selectedForm)
    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute top-[60%] translate-y-[-18%] translate-x-[-4%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width * 0.3},${height / 2} 1,${height - 1}`} class="triangle" />
                </svg>
            </div>
        case -1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-12%] translate-x-[-4%]`}>
                <svg height={height1} width={width1}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)", strokeDasharray: 2  }} points={`30, 25, ${width1 / 1.7},${height1 / 2} 30,${height1 - 26}`} class="triangle" />
                </svg>
                <svg height={height1} width={width1} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)"}} points={`${width1 * 0.39},${height1 * 0.33} ${width1 / 1.7},${height1 / 2} ${width1 * 0.39},${height1 * 0.67}`} class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-12%] translate-x-[-4%]`}>
                <svg height={height1} width={width1}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width1 / 1.7},${height1 / 2} 1,${height1 - 1}`} class="triangle" />
                </svg>
                <svg height={height1} width={width1} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`${width1 * 0.15},${height1 * 0.13} ${width1 / 1.7},${height1 / 2} ${width1 * 0.15},${height1 * 0.87}`} class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className={`absolute top-[60%] translate-y-[-18%] translate-x-[-4%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width / 1.7},${height / 2} 1,${height - 1}`} class="triangle" />
                </svg>
            </div>

    }
}

export const ThirdContainerTriangle3 = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, ...props }) => {

    const height = (((containerHeight / centerVerticalIndex) + 6) * 2) + 20
    const width = (((containerWidth / horizontalLastIndex) + 7) * 2) + 10

    const height1 = (((containerHeight / centerVerticalIndex) + 6) * 2.9) + 22
    const width1 = (((containerWidth / horizontalLastIndex) + 7) * 2.9)


    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute top-[60%] translate-y-[-12%] translate-x-[-4%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width / 1.7},${height / 2} 1,${height - 1}`} class="triangle" />
                </svg>
            </div>
        case -1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-9%] translate-x-[-35%]`}>
                <svg height={height1} width={width1}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)", strokeDasharray: 2  }} points={`25, 22 ${width1 / 1.7},${height1 / 2} ${width1 * 0.13},${height1 * 0.89}`} class="triangle" />
                </svg>
                <svg height={height1} width={width1} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)"}} points={`${width1 * 0.3},${height1 * 0.25} ${width1 / 1.7},${height1 / 2} ${width1 * 0.3},${height1 * 0.75}`} class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-9%] translate-x-[-3%]`}>
                <svg height={height1} width={width1}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width1 / 1.7},${height1 / 2} 1,${height1 - 1}`} class="triangle" />
                </svg>
                <svg height={height1} width={width1} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`${width1 * 0.13},${height1 * 0.11} ${width1 / 1.7},${height1 / 2} ${width1 * 0.13},${height1 * 0.89}`} class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className={`absolute top-[60%] translate-y-[-12%] translate-x-[-4%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width / 1.7},${height / 2} 1,${height - 1}`} class="triangle" />
                </svg>
            </div>

    }
}

