
function stringify(val) {
    return JSON.stringify(val)
}

// const horizontalStripe = 1
const verticalStripe = 0



// const verticalLength = 15
// const verticalLastIndex = verticalLength - 1

// const centerVerticalIndex = verticalLastIndex / 2
const n1CenterIndex = 2
const n2CenterIndex = 8
const n3CenterIndex = 14



const n1 = [
    stringify([8, 2]),
    stringify([8, 3]),
]

const n2 = [
    stringify([6, 7]),
    stringify([6, 8]),
    stringify([6, 9]),
    stringify([8, 7]),
    stringify([8, 8]),
    stringify([8, 9]),
]

const n3 = [
    stringify([6, 13]),
    stringify([6, 14]),
    stringify([6, 15]),
    stringify([6, 16]),
    stringify([8, 13]),
    stringify([8, 14]),
    stringify([8, 15]),
    stringify([8, 16]),
    stringify([10, 13]),
    stringify([10, 14]),
    stringify([10, 15]),
    stringify([10, 16]),
]

const acceptedCoords = [
    stringify([verticalStripe, n1[0], 0, 'stripe']),
    stringify([verticalStripe, n1[1], 0, 'stripe']),

    stringify([verticalStripe, n1[0], -1, 'stripe']),
    stringify([verticalStripe, n1[1], -1, 'stripe']),

    stringify([verticalStripe, n1[0], 0, 'rectangle']),
    stringify([verticalStripe, n1[1], 0, 'rectangle']),

    stringify([verticalStripe, n1[0], -1, 'rectangle']),
    stringify([verticalStripe, n1[1], -1, 'rectangle']),

    //n2
    stringify([verticalStripe, n2[0], 0, 'stripe']),
    stringify([verticalStripe, n2[1], 0, 'stripe']),
    stringify([verticalStripe, n2[2], 0, 'stripe']),

    stringify([verticalStripe, n2[0], -1, 'stripe']),
    stringify([verticalStripe, n2[1], -1, 'stripe']),
    stringify([verticalStripe, n2[2], -1, 'stripe']),
    stringify([verticalStripe, stringify([4,7]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,8]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,9]), -1, 'stripe']),

    stringify([verticalStripe, stringify([7,7]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7,8]), 0, 'rectangle']),
    

    stringify([verticalStripe, stringify([5,8]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,8]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,7]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,7]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,9]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,9]), -1, 'rectangle']),

    //n3
    stringify([verticalStripe, n3[0], 0, 'stripe']),
    stringify([verticalStripe, n3[1], 0, 'stripe']),
    stringify([verticalStripe, n3[2], 0, 'stripe']),
    stringify([verticalStripe, n3[3], 0, 'stripe']),

    stringify([verticalStripe, n3[0], -1, 'stripe']),
    stringify([verticalStripe, n3[1], -1, 'stripe']),
    stringify([verticalStripe, n3[2], -1, 'stripe']),
    stringify([verticalStripe, n3[3], -1, 'stripe']),
    stringify([verticalStripe, stringify([4,13]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,14]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,15]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,16]), -1, 'stripe']),

    stringify([verticalStripe, stringify([8, 14]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([8, 15]), 0, 'rectangle']),
    

    stringify([verticalStripe, stringify([8,14]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([8,15]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([8,16]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([6,14]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([6,15]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([6,16]), -1, 'rectangle']),
    


]

export const rektangeltallene = {

    n1: n1,
    n2: n2,
    n3: n3,
    acceptedCoords: acceptedCoords,

    n1CenterIndex: n1CenterIndex,
    n2CenterIndex: n2CenterIndex,
    n3CenterIndex: n3CenterIndex,

    shape: [['stripe', 0], ['rectangle', 0]],

    name: 'Rektangeltallene',
    formula: 'n ^ 2 + n',



}