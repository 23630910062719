
function stringify(val) {
    return JSON.stringify(val)
}

// const horizontalStripe = 1
const verticalStripe = 0



// const verticalLength = 15
// const verticalLastIndex = verticalLength - 1

// const centerVerticalIndex = verticalLastIndex / 2
const n1CenterIndex = 2
const n2CenterIndex = 8
const n3CenterIndex = 10



const n1 = [
    stringify([7, 1]),
    stringify([7, 2]),
    stringify([7, 3]),
]

const n2 = [

    stringify([6, 6]),
    stringify([6, 7]),
    stringify([6, 8]),
    stringify([6, 9]),
    stringify([6, 10]),

    stringify([8, 7]),
    stringify([8, 8]),
    stringify([8, 9]),
    stringify([8, 10]),

]

const n3 = [

    stringify([5, 11]),
    stringify([5, 12]),
    stringify([5, 13]),
    stringify([5, 14]),
    stringify([5, 15]),
    stringify([5, 16]),
    stringify([5, 17]),

    stringify([7, 12]),
    stringify([7, 13]),
    stringify([7, 14]),
    stringify([7, 15]),
    stringify([7, 16]),
    stringify([7, 17]),

    stringify([9, 13]),
    stringify([9, 14]),
    stringify([9, 15]),
    stringify([9, 16]),
    stringify([9, 17]),
]

const acceptedCoords = [

    stringify([verticalStripe, n1[0], 0, 'stripe']),
    stringify([verticalStripe, n1[1], 0, 'stripe']),
    stringify([verticalStripe, n1[2], 0, 'stripe']),
    stringify([verticalStripe, n1[0], -1, 'stripe']),
    stringify([verticalStripe, n1[1], -1, 'stripe']),
    stringify([verticalStripe, n1[2], -1, 'stripe']),

    stringify([verticalStripe, n1[0], 0, 'rectangle']),
    stringify([verticalStripe, n1[1], 0, 'rectangle']),
    stringify([verticalStripe, n1[2], 0, 'rectangle']),
    stringify([verticalStripe, n1[0], -1, 'rectangle']),
    stringify([verticalStripe, n1[1], -1, 'rectangle']),
    stringify([verticalStripe, n1[2], -1, 'rectangle']),

    stringify([verticalStripe, n1[0], 0, 'triangle1']),
    stringify([verticalStripe, n1[1], 0, 'triangle1']),
    stringify([verticalStripe, n1[2], 0, 'triangle1']),
    stringify([verticalStripe, n1[0], -1, 'triangle1']),
    stringify([verticalStripe, n1[1], -1, 'triangle1']),
    stringify([verticalStripe, n1[2], -1, 'triangle1']),

    //n2
    stringify([verticalStripe, n2[1], 0, 'stripe']),
    stringify([verticalStripe, n2[2], 0, 'stripe']),
    stringify([verticalStripe, n2[3], 0, 'stripe']),
    stringify([verticalStripe, n2[4], 0, 'stripe']),
    stringify([verticalStripe, n2[1], -1, 'stripe']),
    stringify([verticalStripe, n2[2], -1, 'stripe']),
    stringify([verticalStripe, n2[3], -1, 'stripe']),
    stringify([verticalStripe, n2[4], -1, 'stripe']),

    stringify([verticalStripe, stringify([7,7]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7,8]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7,9]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7,7]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,8]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,9]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,10]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,6]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,7]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,8]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,9]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,10]), -1, 'rectangle']),

    stringify([verticalStripe, stringify([7,6]), 0, 'triangle1']),
    stringify([verticalStripe, stringify([7,7]), 0, 'triangle1']),
    stringify([verticalStripe, stringify([7,8]), 0, 'triangle1']),
    stringify([verticalStripe, stringify([7,9]), 0, 'triangle1']),
    stringify([verticalStripe, stringify([7,6]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([7,7]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([7,8]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([7,9]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([7,10]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([9,7]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([9,8]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([9,9]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([9,10]), -1, 'triangle1']),

    //n3
    stringify([verticalStripe, n3[2], 0, 'stripe']),
    stringify([verticalStripe, n3[3], 0, 'stripe']),
    stringify([verticalStripe, n3[4], 0, 'stripe']),
    stringify([verticalStripe, n3[5], 0, 'stripe']),
    stringify([verticalStripe, n3[6], 0, 'stripe']),
    stringify([verticalStripe, n3[2], -1, 'stripe']),
    stringify([verticalStripe, n3[3], -1, 'stripe']),
    stringify([verticalStripe, n3[4], -1, 'stripe']),
    stringify([verticalStripe, n3[5], -1, 'stripe']),
    stringify([verticalStripe, n3[6], -1, 'stripe']),

    stringify([verticalStripe, stringify([7,14]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7,15]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7,16]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7,14]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,15]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,16]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,17]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,13]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,14]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,15]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,16]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,17]), -1, 'rectangle']),

    stringify([verticalStripe, stringify([7, 12]), 0, 'triangle1']),
    stringify([verticalStripe, stringify([7, 13]), 0, 'triangle1']),
    stringify([verticalStripe, stringify([7, 14]), 0, 'triangle1']),
    stringify([verticalStripe, stringify([7, 15]), 0, 'triangle1']),
    stringify([verticalStripe, stringify([7, 16]), 0, 'triangle1']),
    
    stringify([verticalStripe, stringify([7,12]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([7,13]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([7,14]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([7,15]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([7,16]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([7,17]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([9,13]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([9,14]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([9,15]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([9,16]), -1, 'triangle1']),
    stringify([verticalStripe, stringify([9,17]), -1, 'triangle1']),
    


]

export const båttallene = {

    n1: n1,
    n2: n2,
    n3: n3,
    acceptedCoords: acceptedCoords,

    n1CenterIndex: n1CenterIndex,
    n2CenterIndex: n2CenterIndex,
    n3CenterIndex: n3CenterIndex,

    shape: [['stripe', 0], ['rectangle', 0], ['triangle1', 0]],

    name: 'Båttallene',
    formula: '(n ^ 2 + n) * 3 / 2',



}