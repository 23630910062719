
function stringify(val) {
    return JSON.stringify(val)
}

const horizontalStripe = 1
const verticalStripe = 0



// const verticalLength = 15
// const verticalLastIndex = verticalLength - 1

// const centerVerticalIndex = verticalLastIndex / 2
const n1CenterIndex = 2
const n2CenterIndex = 8
const n3CenterIndex = 14



const n1 = [
    stringify([5, 1]),
    stringify([5, 3]),
    stringify([7, 1]),
    stringify([7, 3]),
    stringify([9, 2]),
]

const n2 = [
    stringify([4, 6]),
    stringify([4, 7]),
    stringify([4, 9]),
    stringify([4, 10]),

    stringify([6, 6]),
    stringify([6, 7]),
    stringify([6, 9]),
    stringify([6, 10]),

    stringify([8, 6]),
    stringify([8, 7]),
    stringify([8, 9]),
    stringify([8, 10]),

    stringify([10, 8]),
]

const n3 = [
    stringify([3, 11]),
    stringify([3, 12]),
    stringify([3, 13]),
    stringify([3, 15]),
    stringify([3, 16]),
    stringify([3, 17]),

    stringify([5, 11]),
    stringify([5, 12]),
    stringify([5, 13]),
    stringify([5, 15]),
    stringify([5, 16]),
    stringify([5, 17]),

    stringify([7, 11]),
    stringify([7, 12]),
    stringify([7, 13]),
    stringify([7, 15]),
    stringify([7, 16]),
    stringify([7, 17]),

    stringify([9, 11]),
    stringify([9, 12]),
    stringify([9, 13]),
    stringify([9, 15]),
    stringify([9, 16]),
    stringify([9, 17]),

    stringify([11, 14]),

]

const acceptedCoords = [
    stringify([horizontalStripe, n1[0], 0, 'stripe']),
    stringify([horizontalStripe, n1[1], 0, 'stripe']),
    stringify([horizontalStripe, n1[2], 0, 'stripe']),
    stringify([horizontalStripe, n1[3], 0, 'stripe']),
    stringify([horizontalStripe, n1[4], 0, 'stripe']),

    stringify([horizontalStripe, n1[0], -1, 'stripe']),
    stringify([horizontalStripe, n1[1], -1, 'stripe']),
    stringify([horizontalStripe, n1[2], -1, 'stripe']),
    stringify([horizontalStripe, n1[3], -1, 'stripe']),
    stringify([horizontalStripe, n1[4], -1, 'stripe']),

    stringify([verticalStripe, n1[0], 0, 'rectangle']),
    stringify([verticalStripe, n1[1], 0, 'rectangle']),
    stringify([verticalStripe, n1[2], 0, 'rectangle']),
    stringify([verticalStripe, n1[3], 0, 'rectangle']),
    stringify([verticalStripe, n1[4], 0, 'rectangle']),

    stringify([verticalStripe, n1[0], -1, 'rectangle']),
    stringify([verticalStripe, n1[1], -1, 'rectangle']),
    stringify([verticalStripe, n1[2], -1, 'rectangle']),
    stringify([verticalStripe, n1[3], -1, 'rectangle']),
    stringify([verticalStripe, n1[4], -1, 'rectangle']),


    //n2
    stringify([horizontalStripe, n2[0], 0, 'stripe']),
    stringify([horizontalStripe, n2[2], 0, 'stripe']),
    stringify([horizontalStripe, n2[4], 0, 'stripe']),
    stringify([horizontalStripe, n2[6], 0, 'stripe']),
    stringify([horizontalStripe, n2[8], 0, 'stripe']),
    stringify([horizontalStripe, n2[10], 0, 'stripe']),

    stringify([horizontalStripe, n2[0], -1, 'stripe']),
    stringify([horizontalStripe, n2[2], -1, 'stripe']),
    stringify([horizontalStripe, n2[4], -1, 'stripe']),
    stringify([horizontalStripe, n2[6], -1, 'stripe']),
    stringify([horizontalStripe, n2[8], -1, 'stripe']),
    stringify([horizontalStripe, n2[10], -1, 'stripe']),
    stringify([horizontalStripe, stringify([8,7]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([6,7]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([4,7]), -1, 'stripe']),


    
    stringify([verticalStripe, stringify([5,6]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7,6]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([5,9]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7,9]), 0, 'rectangle']),


    stringify([verticalStripe, stringify([3,6]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,6]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,6]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([3,9]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,9]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,9]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([3,7]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,7]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,7]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([3,10]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5,10]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7,10]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([9,8]), -1, 'rectangle']),
   

    stringify([horizontalStripe, n3[0], 0, 'stripe']),
    stringify([horizontalStripe, n3[3], 0, 'stripe']),
    stringify([horizontalStripe, n3[6], 0, 'stripe']),
    stringify([horizontalStripe, n3[9], 0, 'stripe']),
    stringify([horizontalStripe, n3[12], 0, 'stripe']),
    stringify([horizontalStripe, n3[15], 0, 'stripe']),
    stringify([horizontalStripe, n3[18], 0, 'stripe']),
    stringify([horizontalStripe, n3[21], 0, 'stripe']),
    stringify([horizontalStripe, n3[0], -1, 'stripe']),
    stringify([horizontalStripe, n3[3], -1, 'stripe']),
    stringify([horizontalStripe, n3[6], -1, 'stripe']),
    stringify([horizontalStripe, n3[9], -1, 'stripe']),
    stringify([horizontalStripe, n3[12], -1, 'stripe']),
    stringify([horizontalStripe, n3[15], -1, 'stripe']),
    stringify([horizontalStripe, n3[18], -1, 'stripe']),
    stringify([horizontalStripe, n3[21], -1, 'stripe']),
    stringify([horizontalStripe, stringify([3,12]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([5,12]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([7,12]), -1, 'stripe']),
    stringify([horizontalStripe, stringify([9,12]), -1, 'stripe']),



    stringify([verticalStripe, stringify([5, 12]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7, 12]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([5, 16]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7, 16]), 0, 'rectangle']),
    stringify([verticalStripe, stringify([7, 12]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5, 12]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([3, 12]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([3, 13]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5, 13]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7, 13]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7, 16]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5, 16]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([3, 16]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([3, 17]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([5, 17]), -1, 'rectangle']),
    stringify([verticalStripe, stringify([7, 17]), -1, 'rectangle']),
    






]

export const balansetallene = {

    n1: n1,
    n2: n2,
    n3: n3,
    acceptedCoords: acceptedCoords,

    n1CenterIndex: n1CenterIndex,
    n2CenterIndex: n2CenterIndex,
    n3CenterIndex: n3CenterIndex,

    shape: [['stripe', 1], ['rectangle', 0]],

    name: 'Balansetallene',
    formula: '2 * (n ^ 2 + n) + 1',



}