export const RectangleStripe = ({ selectedForm, ...props }) => {
    switch (selectedForm) {
        case -1:
            return <div {...props} className='relative'>
                <div className='w-[50px] h-[50px] border-dotted border-2 border-teal-600'></div>
                <div className='absolute bottom-0 w-[40px] h-[40px] border-2 border-teal-600'></div>
            </div>
        case 0:
            return <div {...props} className='w-[50px] h-[50px] border-2 border-teal-600'></div>
        case 1:
            return <div {...props} className='relative'>
                <div className='w-[50px] h-[50px] border-2 border-teal-600'></div>
                <div className='absolute bottom-0 w-[40px] h-[40px] border-2 border-teal-600'></div>
            </div>
        default:
            return <div {...props} className='w-[50px] h-[50px] border-2 border-teal-600'></div>
    }
}

// Shapes to show on the dots
export const FirstContainerRectangle = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, ...props }) => {

    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] h-[14px] w-[14px] border-2  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
        case -1:
            return <div {...props} className={`absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] h-[14px] w-[14px] border-2 border-dashed  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
        case 1:
            return <div {...props} className="absolute relative top-[-60%] translate-x-[-60%]">
                <div style={{ height: (containerHeight / centerVerticalIndex) + 13, width: (containerHeight / centerVerticalIndex) + 15 }} className={`absolute border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                <div style={{ height: ((containerHeight / centerVerticalIndex) + 13) / 2, width: ((containerHeight / centerVerticalIndex) + 15) / 2, top: ((containerHeight / centerVerticalIndex) + 13) / 2 }} className={`absolute border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
            </div>
        default:
            return <div {...props} className={`absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] h-[14px] w-[14px] border-2  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>

    }
}

export const SecondContainerRectangle = ({ isAccepted, selectedForm, selectedStripe, containerHeight, centerVerticalIndex, containerWidth, horizontalStripe, horizontalLastIndex, ...props }) => {

    switch (selectedForm) {
        case 0: return <div {...props}><div style={{ width: (containerWidth / horizontalLastIndex) + 14, height: (containerHeight / centerVerticalIndex) + 13 }} className={`absolute top-[-60%] translate-y-[-40%] translate-x-[-45%] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div></div>

        case -1:
            return <div {...props} className="absolute top-[-60%] left-[0%] translate-y-[-40%] translate-x-[-45%] ">
                <div style={{ height: (containerHeight / centerVerticalIndex) + 13, width: (containerWidth / horizontalLastIndex) + 14 }} className={` w-[14px] border-2 border-dashed ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                <div style={{ height: ((containerHeight / centerVerticalIndex) + 13) / 2, width: ((containerWidth / horizontalLastIndex) + 14) / 2, top: ((containerHeight / centerVerticalIndex) + 13) / 2 }} className={` absolute w-[14px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
            </div>
        case 1:
            return <div {...props} className="absolute  top-[-60%] left-[0%] translate-y-[-45%] translate-x-[-47%]">
                <div style={{ height: ((containerHeight / centerVerticalIndex) + 6) * 2, width: ((containerWidth / horizontalLastIndex) + 7) * 2 }} className={` w-[14px] border-2  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                <div style={{ height: ((containerHeight / centerVerticalIndex) + 7) * 1.43, top: ((containerHeight / centerVerticalIndex) + 5) / 1.8, width: ((containerWidth / horizontalLastIndex) + 7) * 1.45 }} className={`absolute  w-[14px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
            </div>
        default:
    }

    // return <div {...props} draggable style={{ width: (containerWidth / horizontalLastIndex) + 14 }} className={`absolute top-[-60%] left-[-60%]  translate-x-[-50%  h-[14px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
}

export const ThirdContainerRectangle = ({ isAccepted, selectedForm, selectedStripe, containerHeight, centerVerticalIndex, containerWidth, horizontalStripe, horizontalLastIndex, ...props }) => {

    switch (selectedForm) {
        case 0:
            return <div {...props}><div style={{ height: ((containerHeight / centerVerticalIndex) + 6) * 2, width: ((containerWidth / horizontalLastIndex) + 7) * 2 }} className={`absolute top-[-60%] left-[0%] translate-y-[-44%] translate-x-[-47%]  w-[14px] border-2  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div></div>
        case -1:
            return <div {...props} className="absolute top-[-60%] left-[0%] translate-y-[-44%] translate-x-[-47%]">
                
                    <div style={{ height: ((containerHeight / centerVerticalIndex) + 7) * 1.43, top: ((containerHeight / centerVerticalIndex) + 5) / 1.8, width: ((containerWidth / horizontalLastIndex) + 7) * 1.45 }} className={`absolute  w-[14px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                    <div style={{ height: ((containerHeight / centerVerticalIndex) + 6) * 2, width: ((containerWidth / horizontalLastIndex) + 7) * 2 }} className={` w-[14px] border-2 border-dashed  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>

                </div>
        case 1:
            return <div {...props} className="absolute  top-[-60%] left-[0%] translate-y-[-57.5%] translate-x-[-60%]">
                <div>
                    <div style={{ height: ((containerHeight / centerVerticalIndex) + 6) * 2.9, width: ((containerWidth / horizontalLastIndex) + 7) * 2.9 }} className={`absolute w-[14px] border-2  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                    <div style={{ height: (((containerHeight / centerVerticalIndex) + 6) * 2.9) / 1.23, width: (((containerWidth / horizontalLastIndex) + 7) * 2.9) / 1.24, top: (((containerHeight / centerVerticalIndex) + 6) * 2.9) / 5.35 }} className={`  w-[14px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                </div>
            </div>
        default:
    }

    // return <div {...props} draggable style={{ width: (containerWidth / horizontalLastIndex) + 14 }} className={`absolute top-[-60%] left-[-60%]  translate-x-[-50%  h-[14px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
}