export const VerticalStripe = ({ selectedForm, ...props }) => {

    switch (selectedForm) {
        case -1:
            return <div {...props} className='relative'>
                <div className=' h-[50px] w-[10px] border-2 border-teal-600 rounded-md border-dotted'></div>
                <div className='absolute bottom-0 h-[40px] w-[10px] border-2 border-teal-600 rounded-b-md'></div>
            </div>
        case 0:
            return <div {...props} className='h-[50px] w-[10px] border-2 border-teal-600 rounded-md'></div>
        case 1:
            return <div {...props} className='relative'>
                <div className=' h-[50px] w-[10px] border-2 border-teal-600 rounded-md'></div>
                <div className='absolute bottom-0 h-[40px] w-[10px] border-2 border-teal-600 rounded-b-md'></div>
            </div>
        default:
            return <div {...props} className='h-[50px] w-[10px] border-2 border-teal-600 rounded-md'></div>
    }
}

export const HorizontalStripe = ({ selectedForm, ...props }) => {
    switch (selectedForm) {
        case -1:
            return <div {...props} className='relative'>
                <div className='w-[50px] h-[10px] border-2 border-teal-600 rounded-md border-dotted'></div>
                <div className='absolute top-0 w-[40px] h-[10px] border-2 border-teal-600 rounded-l-md'></div>
            </div>
        case 0:
            return <div {...props} className='w-[50px] h-[10px] border-2 border-teal-600 rounded-md'></div>
        case 1:
            return <div {...props} className='relative'>
                <div className='w-[50px] h-[10px] border-2 border-teal-600 rounded-md'></div>
                <div className='absolute top-0 w-[40px] h-[10px] border-2 border-teal-600 rounded-l-md'></div>
            </div>
        default:
            return <div {...props} className='w-[50px] h-[10px] border-2 border-teal-600 rounded-md'></div>
    }
}



// Shapes to show on the dots
export const FirstContainerStripe = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, isShapeShake, ...props }) => {
    // ${isShapeShake ? 'input-invalid' : ''}
    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] h-[14px] w-[14px] rounded-[7px] border-2  ${isAccepted ? 'border-teal-600' : `border-red-600`}`}></div>
        case - 1:
            return <div {...props} className={`absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] h-[14px] w-[14px] rounded-[7px] border-2 border-dashed  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
        case 1:

            if (selectedStripe === 1) {
                return <div {...props} className="absolute relative top-[-60%] left-[0%] translate-y-[-150%] translate-x-[-60%]">
                    <div style={{ width: (containerWidth / horizontalLastIndex) + 14 }} className={`absolute h-[14px] rounded-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                    <div style={{ width: ((containerWidth / horizontalLastIndex) + 14) / 2 }} className={`absolute h-[14px] rounded-l-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                </div>
            }
            return <div {...props} className="absolute relative top-[-60%] left-[0%] translate-y-[-150%] translate-x-[-60%]">
                <div style={{ height: (containerHeight / centerVerticalIndex) + 13 }} className={`absolute w-[14px] rounded-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                <div style={{ height: ((containerHeight / centerVerticalIndex) + 13) / 2 }} className={`absolute w-[14px] rounded-t-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
            </div>


        default:
            return <div {...props} className={`absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] h-[14px] w-[14px] rounded-[7px] border-2  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>

    }
}

export const SecondContainerStripe = ({ isAccepted, selectedForm, selectedStripe, containerHeight, centerVerticalIndex, containerWidth, horizontalStripe, horizontalLastIndex, ...props }) => {

    switch (selectedForm) {
        case 0:
            if (selectedStripe === 1) {
                return <div {...props}><div style={{ width: (containerWidth / horizontalLastIndex) + 14 }} className={`absolute top-[-60%] left-[-60%]  translate-x-[-50%  h-[14px] rounded-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div></div>
            }
            return <div {...props} ><div style={{ height: (containerHeight / centerVerticalIndex) + 13 }} className={`absolute top-[-60%] left-[-65%]  translate-x-[-50%  w-[14px] rounded-[7px] border-2  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div></div>
        case -1:
            if (selectedStripe === 1) {
                return <div {...props} className="absolute relative top-[-60%] left-[0%] translate-y-[-150%] translate-x-[-60%]">
                    <div style={{ width: (containerWidth / horizontalLastIndex) + 14 }} className={`absolute h-[14px] rounded-[7px] border-2 border-dashed ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                    <div style={{ width: ((containerWidth / horizontalLastIndex) + 14) / 2 }} className={`absolute h-[14px] rounded-l-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                </div>
            }
            return <div {...props} className="absolute relative top-[-60%] left-[0%] translate-y-[-150%] translate-x-[-60%]">
                <div style={{ height: (containerHeight / centerVerticalIndex) + 13 }} className={`absolute w-[14px] rounded-[7px] border-2 border-dashed ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                <div style={{ height: ((containerHeight / centerVerticalIndex) + 13) / 2, top: ((containerHeight / centerVerticalIndex) + 13) / 2 }} className={`absolute  w-[14px] rounded-b-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
            </div>
        case 1:
            if (selectedStripe === 1) {
                return <div {...props} className="absolute relative top-[-60%] left-[0%] translate-y-[-150%] translate-x-[-60%]">
                    <div style={{ width: ((containerWidth / horizontalLastIndex) + 7) * 2 }} className={`absolute h-[14px] rounded-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                    <div style={{ width: (((containerWidth / horizontalLastIndex) + 7) * 2) / 1.39 }} className={`absolute h-[14px] rounded-l-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                </div>
            }
            return <div {...props} className="absolute relative top-[-60%] left-[0%] translate-y-[-150%] translate-x-[-60%]">
                <div style={{ height: ((containerHeight / centerVerticalIndex) + 6) * 2 }} className={`absolute w-[14px] rounded-[7px] border-2  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                <div style={{ height: ((containerHeight / centerVerticalIndex) + 6) * 2 / 1.39, top: ((containerHeight / centerVerticalIndex) + 13) / 2 }} className={`absolute  w-[14px] rounded-b-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
            </div>
        default:
    }

    // return <div {...props} draggable style={{ width: (containerWidth / horizontalLastIndex) + 14 }} className={`absolute top-[-60%] left-[-60%]  translate-x-[-50%  h-[14px] rounded-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
}

export const ThirdContainerStripe = ({ isAccepted, selectedForm, selectedStripe, containerHeight, centerVerticalIndex, containerWidth, horizontalStripe, horizontalLastIndex, ...props }) => {

    switch (selectedForm) {
        case 0:
            if (selectedStripe === 1) {
                return <div {...props} ><div style={{ width: ((containerWidth / horizontalLastIndex) + 7) * 2 }} className={`absolute top-[-60%] left-[-60%]  translate-x-[-50%  h-[14px] rounded-[7px] border-2  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div></div>
            }
            return  <div {...props}><div  style={{ height: ((containerHeight / centerVerticalIndex) + 6) * 2 }} className={`absolute top-[-60%] left-[-65%]  translate-x-[-50%  w-[14px] rounded-[7px] border-2  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div></div>
        case -1:
            if (selectedStripe === 1) {
                return <div {...props} className="absolute relative top-[-60%] left-[0%] translate-y-[-150%] translate-x-[-60%]">
                    <div style={{ width: ((containerWidth / horizontalLastIndex) + 7) * 2 }} className={`absolute h-[14px] rounded-[7px] border-2 border-dashed ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                    <div style={{ width: (((containerWidth / horizontalLastIndex) + 7) * 2) / 1.39 }} className={`absolute h-[14px] rounded-l-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                </div>
            }
            return <div {...props} className="absolute relative top-[-60%] left-[0%] translate-y-[-150%] translate-x-[-60%]">
                <div style={{ height: ((containerHeight / centerVerticalIndex) + 6) * 2 }} className={`absolute w-[14px] rounded-[7px] border-2 border-dashed  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                <div style={{ height: ((containerHeight / centerVerticalIndex) + 6) * 2 / 1.39, top: ((containerHeight / centerVerticalIndex) + 13) / 2 }} className={`absolute  w-[14px] rounded-b-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
            </div>
        case 1:
            if (selectedStripe === 1) {
                return <div {...props} className="absolute relative top-[-60%] left-[0%] translate-y-[-150%] translate-x-[-60%]">
                    <div style={{ width: ((containerWidth / horizontalLastIndex) + 7) * 2.9 }} className={`absolute h-[14px] rounded-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                    <div style={{ width: (((containerWidth / horizontalLastIndex) + 7) * 3) / 1.28 }} className={`absolute h-[14px] rounded-l-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                </div>
            }
            return <div {...props} className="absolute relative top-[-60%] left-[0%] translate-y-[-150%] translate-x-[-60%]">
                <div style={{ height: ((containerHeight / centerVerticalIndex) + 6) * 2.9 }} className={`absolute w-[14px] rounded-[7px] border-2  ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
                <div style={{ height: ((containerHeight / centerVerticalIndex) + 6) * 3 / 1.28, top: ((containerHeight / centerVerticalIndex) + 13) / 2 }} className={`absolute  w-[14px] rounded-b-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
            </div>
        default:
    }

    // return <div {...props} draggable style={{ width: (containerWidth / horizontalLastIndex) + 14 }} className={`absolute top-[-60%] left-[-60%]  translate-x-[-50%  h-[14px] rounded-[7px] border-2 ${isAccepted ? 'border-teal-600' : 'border-red-600'}`}></div>
}