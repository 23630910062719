import { Tooltip } from '@mantine/core'
import EquationEditor from 'equation-editor-react'
import { parse } from 'mathjs'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import { evaluateTex } from 'tex-math-parser'
import trash from '../assets/icons/trash.svg'
import { RenderDot } from '../components/GridComponents/RenderDot'
import { RectangleStripe } from '../shapes/displayShapes/rectangle'
import { HorizontalStripe, VerticalStripe } from '../shapes/displayShapes/stripe'
import { Triangle1Stripe } from '../shapes/displayShapes/triangle1'
import { Triangle2Stripe } from '../shapes/displayShapes/triangle2'
import { Triangle3Stripe } from '../shapes/displayShapes/triangle3'
import { Triangle4Stripe } from '../shapes/displayShapes/triangle4'
import { shapesConfig } from '../shapesConfig/shapesConfigs'

export function useCallbackRef(callback) {
    const callbackRef = useRef(callback);

    useLayoutEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    return callbackRef;
}

function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2))
}

function stringify(val) {
    return JSON.stringify(val)
}

const FigureNumber = ({setIsPlayerActive, setUrl}, ref) => {
    const [equation, setEquation] = useState("");
    const equationRef = useCallbackRef(equation)
    const [formulaStatus, setFormulaStatus] = useState(0)
    const [isShake, setIsShake] = useState(false)
    const [isShapeShake, setIsShapeShake] = useState(false)
    const [seed, setSeed] = useState('krysstallene');

    const [draggedElementCoords, setDraggedElementCoords] = useState(null)

    const [showLevels, setShowLevels] = useState(false)
    const [levelHover, setLevelHover] = useState(false)

    const positionRef = useRef({})

    const [selectedForm, setSelectedForm] = useState(0)

    const isDropAccepted = useRef(null)
    const pickedStripe = useRef(null)
    const [nearestCoord, setNearestCoord] = useState([])
    const [droppedCoords, setDroppedCords] = useState([])

    const [{ n1, n2, n3, acceptedCoords, shape, name, ...state }, setCurrentShape] = useState(shapesConfig.krysstallene)


    const getFormulaClassName = useCallback(() => {
        if (formulaStatus === 0) return { field: 'border-black', frame: 'border-[#000000]' }
        if (formulaStatus === -1) return { field: 'border-red-500 bg-red-100', frame: 'border-red-500' }
        if (formulaStatus === 1) return { field: 'border-teal-500 bg-green-200', frame: 'border-teal-200 ' }
    }, [formulaStatus])


    const changeShapeConfig = (actionValue, selectedIndex = 0) => {
        const keys = Object.keys(shapesConfig)
        const index = keys.indexOf(name.toLowerCase())
        let nextKey;
        if (actionValue === "increase") {
            nextKey = index + 1 === keys.length ? keys[index] : keys[index + 1]
            setSeed(nextKey)
            setCurrentShape({ ...shapesConfig[nextKey] })
        }
        else if(actionValue === "decrease") {
            nextKey = index <= 0 ? keys[index] : keys[index - 1]
            setSeed(nextKey)
            setCurrentShape({ ...shapesConfig[nextKey] })
        } else if(actionValue === "menuPress") {
            setSeed(selectedIndex)
        }
        setEquation("")
        
    }


    const getCurrentIndex = useCallback(() => {
        const keys = Object.keys(shapesConfig)
        const index = keys.indexOf(name.toLowerCase())
        

        const isFirst = index === 0
        const isLast = index + 1 === keys.length

        return { isFirst, isLast, index: index + 1 }
    }, [name])


    // const dotHeight = 6
    // const dotWidth = 6

    const containerHeight = 400



    const horizontalStripe = 1
    const verticalStripe = 0
    const [selectedStripe, setSelectedStripe] = useState(horizontalStripe)
    const [selectedShape, setSelectedShape] = useState('stripe')

    const checkEquation = () => {

    
        try {
            // const simplifiedFormula = simplify(equation.replaceAll('\\', '').replaceAll(',', '.')).toString()

            // const formula1 = parse(simplifiedFormula).evaluate({ n: 3, x: 3, y: 3, a: 3 })
            console.log(equationRef)

            const formula1 = evaluateTex(equationRef.current.replaceAll(",", "."), { n: -1000}).evaluated
            const formula2 = parse(state?.formula).evaluate({ n: -1000 })
        
            // console.log('Simplified ', simplifiedFormula)
            if (formula1 !== formula2) {
                setFormulaStatus(-1)
                setIsShake(true)
                setTimeout(() => { setIsShake(false) }, 500)
                return
            }
            setFormulaStatus(1)
        }
        catch (e) {
            setFormulaStatus(-1)
            setIsShake(true)
            setTimeout(() => { setIsShake(false) }, 500)
            return
        }

    }


    const horizontalLength = 19
    const altHorizontalLength = horizontalLength - 1
    const horizontalLastIndex = horizontalLength - 1


    const verticalLength = 15
    const verticalLastIndex = verticalLength - 1

    const centerVerticalIndex = verticalLastIndex / 2

    const n1LastIndex = 5
    const n2LastIndex = 11
    const n3LastIndex = horizontalLastIndex


    const containerRef = useRef(null)
    const dragBoxRef = useRef(null)
    const [width, setWidth] = useState(0);



    // const { ref, x, y } = useMouse();
    // const { ref, width, height } = useElementSize();

    // const combinedRef = useMergedRef(ref, dragBoxRef)

    useLayoutEffect(() => {
        setWidth(containerRef.current.offsetWidth);
        window.addEventListener('resize', () => setWidth(containerRef.current.offsetWidth))
    }, [])



    const getDotCoordinates = () => {
        if (positionRef.current) {
            // const coordinateArr = []

            return Object.keys(positionRef.current).map((key) => {
                return { x: positionRef.current[key][0], y: positionRef.current[key][1] }
            })
            // for (const key in positionRef.current) {
            //     coordinateArr.push({ x: positionRef.current[key][0], y: positionRef.current[key][1] })
            // }
            // return coordinateArr

        }
    }


    useEffect(() => {
        setFormulaStatus(0)
    }, [equation])

    useImperativeHandle(ref, () => {
        return {
            onDropOutside: () => {
                let temp = [...new Set(droppedCoords)]

                        if (pickedStripe.current) {
                            temp.splice(temp.indexOf(pickedStripe.current), 1)
                            pickedStripe.current = null
                        }
                        setDroppedCords([...temp])
            }
        }
    })

    return <div className={`w-100 border p-6 rounded-md border-[#000000] select-none h-[684px] w-[1128px]`}>
        <div className='flex items-center justify-between' >
            <div className='flex flex-1 gap-4 items-center'>
                <Tooltip multiline width={200} withArrow label="Slipp en form oppi søppelbøtta hvis du ønsker å slette den.">
                    <img className={`h-auto w-[50px]`} onDragOver={(e) => { e.preventDefault() }} onDrop={() => {
                        let temp = [...new Set(droppedCoords)]

                        if (pickedStripe.current) {
                            temp.splice(temp.indexOf(pickedStripe.current), 1)
                            pickedStripe.current = null
                        }
                        setDroppedCords([...temp])

                    }} src={trash} alt="trash" />
                </Tooltip>
                <Tooltip multiline width={200} withArrow label="Klikk her hvis du ønsker å fjerne alle formene du har plassert i de tre rutene.">
                    <div onClick={() => { setDroppedCords([]) }} className='cursor-pointer text-sm border border-[#ff0000] rounded-md px-5 py-1'>
                        FJERN ALLE FORMENE
                    </div>
                </Tooltip>
            </div>
            <div className='flex flex-1 gap-1 items-center'>
                <Tooltip multiline position='bottom' width={200} withArrow label="Klikk her hvis du ønsker å komme til forrige oppgave.">
                    <div>
                        <ArrowLeft disabled={getCurrentIndex().isFirst} onClick={() => { changeShapeConfig("decrease"); setFormulaStatus(0); setDroppedCords([]); setSelectedForm(0) }} />
                    </div>
                </Tooltip>
                <div className='relative'>
                    <Tooltip opened={!showLevels && levelHover} multiline width={200} position='top' withArrow label="Klikk her hvis du ønsker å få frem en rullgardinsmeny med alle oppgavene.">
                        <div onMouseEnter={() => { setLevelHover(true) }} onMouseLeave={() => { setLevelHover(false) }} onClick={() => { setShowLevels(prev => !prev) }} className='cursor-pointer border-2 border-[#ff0000] px-5 text-center py-1 w-80' style={{ whiteSpace: 'nowrap' }}>
                            OPPGAVE {getCurrentIndex().index} - {name.toUpperCase()}
                        </div>
                    </Tooltip>
                    {showLevels && <div className='absolute h-auto w-[100%] bg-white top-[110%] z-50 border '>
                        {Object.keys(shapesConfig).map((item, index) => {

                            return <p onClick={() => {
                                setCurrentShape({ ...shapesConfig[item] });
                                setShowLevels(false)
                                setFormulaStatus(0); setDroppedCords([]); setSelectedForm(0); changeShapeConfig("menuPress", index);
                            }} key={index} className={`hover:bg-gray-200 cursor-pointer text-center py-1 px-2 border-b ${name === shapesConfig[item].name ? 'bg-gray-400' : ''}`}>{shapesConfig[item].name}</p>
                        })}
                    </div>}
                </div>
                <Tooltip multiline position='bottom' width={200} withArrow label="Klikk her hvis du ønsker å komme til neste oppgave.">
                    <div>
                        <ArrowRight disabled={getCurrentIndex().isLast} onClick={() => { changeShapeConfig("increase"); setFormulaStatus(0); setDroppedCords([]); setSelectedForm(0) }} />
                    </div>
                </Tooltip>
            </div>
            <div className='flex flex-1 gap-2 justify-end'>
                {/* <div style={{ fontSize: 10 }} className='font-bold border-2 border-[#ff0000] p-1 w-[50px] text-center'>LOGG<br />UT</div> */}
                <Tooltip multiline width={200} withArrow label="Klikk her hvis du ønsker å komme til NLAs hjemmeside."><a href='https://www.nla.no/'><img style={{height: 40}} src={require("../assets/icons/NLA.png")} /></a></Tooltip>
                <Tooltip multiline width={200} withArrow label="Klikk her hvis du ønsker å få hjelp til å bruke figurmønster-appen.">
                    <div onClick={() => {
                        setUrl("https://vimeo.com/935325917/7d6a93c08f?share=copy")
                        setIsPlayerActive(true)
                    }} className='rounded-full border-2 border-[#ff0000] h-15 w-[40px] flex justify-center items-center font-bold text-lg text-[#ff0000]'>?</div>
                </Tooltip>
            </div>
        </div>

        <div ref={dragBoxRef} className={`h-[450px] my-5 relative ${getFormulaClassName().frame}`}>
            {/* {nearestCoord && <div className='absolute border-2 border-teal-600 flex self-center' style={{ height: 12, width: 12, borderRadius: 6, left: nearestCoord.x - 3, top: nearestCoord.y - 3 }}></div>} */}
            <div style={{ pointerEvents: 'none' }} className='absolute h-[400px] w-[100%] p-[1.5px]'>
                <div ref={containerRef} className='h-[100%] w-[100%] border-2 border-black'>
                </div>
            </div>
            <div style={{ pointerEvents: 'none' }} className='absolute h-[450px] w-[100%] p-[1.5px]'>
                <div className='h-[100%] w-[100%] border-2 border-black'>
                </div>
            </div>
            <div className='absolute h-[450px] border border-black' style={{ left: width / (horizontalLastIndex / n1LastIndex) + 1.5 }}></div>
            <div className='absolute h-[450px] border border-black' style={{ left: width / (horizontalLastIndex / n2LastIndex) }}></div>
            <div onDragOver={(e) => {
                e.preventDefault();
                e.dataTransfer.dropEffect = "move";
                if (!isDropAccepted.current) return

                const currentCoord = { x: e.nativeEvent.clientX, y: e.nativeEvent.clientY }
                let closestCoord = getDotCoordinates().reduce((a, b) => getDistance(a, currentCoord) < getDistance(b, currentCoord) ? a : b)
                let closestCoordArr = JSON.stringify([closestCoord.x, closestCoord.y])
                if (closestCoord.x === 0 && closestCoord.y === 0) {
                    closestCoordArr = JSON.stringify([nearestCoord.x, nearestCoord.y])
                    closestCoord = { x: nearestCoord.x, y: nearestCoord.y }
                }

                const coords = Object.keys(positionRef.current).find(key => JSON.stringify(positionRef.current[key]) === closestCoordArr);
                if (coords !== nearestCoord) {
                    // setNearestCoord({ id: coords, x: closestCoord.x, y: closestCoord.y })
                    setNearestCoord([selectedStripe, coords, selectedForm, selectedShape])
                }

            }}

                onDrop={(e) => {
                    setDraggedElementCoords(null)
                    const coords = stringify(nearestCoord)
                    let temp = [...new Set(droppedCoords)]

                    if (pickedStripe.current) {
                        temp.splice(temp.indexOf(pickedStripe.current), 1)
                    }

                    temp.push(coords)

                    setDroppedCords([...temp])

                    if (!acceptedCoords.includes(coords)) {
                        setIsShapeShake(true)
                        setTimeout(() => {
                            setIsShapeShake(false)
                            temp.splice(temp.indexOf(coords), 1)
                            setDroppedCords([...temp])

                        }, 1000)
                    }


                    setNearestCoord([])
                    isDropAccepted.current = null
                    pickedStripe.current = null
                }}
                className={`h-[${containerHeight}px] flex flex-col justify-between relative`}>


                {/* <div className='h-[12px] w-[12px] rounded-[6px] absolute border-2 border-teal-500' style={{ left: positionRef?.current?.[`${n1[0]}`][0] - 3, top: positionRef?.current?.[`${n1[0]}`][1] - 3 }}></div> */}
                {dragBoxRef.current && Array(verticalLength).fill().map((item, index) => {
                    return <div key={index} className={`flex  ${(index + 1) % 2 === 0 ? 'justify-around px-[3px]' : 'justify-between'}`}>
                        {Array((index + 1) % 2 === 0 ? altHorizontalLength : horizontalLength).fill().map((it, ind) => {
                            const indexArr = JSON.stringify([index, ind])
                            return <RenderDot
                                key={index + ' ' + ind}
                                containerWidth={width}
                                containerHeight={containerHeight}
                                indexArr={indexArr}
                                nearestCoord={nearestCoord}
                                ringOpacity={nearestCoord[1] === indexArr ? 1 : 0}
                                dragBoxRef={dragBoxRef}
                                onLayout={(val) => {
                                    if (positionRef.current) {
                                        positionRef.current = { ...positionRef.current, [`${indexArr}`]: val }
                                    }
                                }}
                                widthId={ind}
                                heightId={index}
                                isAlternate={(index + 1) % 2 === 0 ? true : false}
                                className={`h-[6px] w-[6px] rounded-[3px] ${[...n1, ...n2, ...n3].includes(indexArr) ? 'bg-[#ff0000]' : ''}`}
                                selectedStripe={selectedStripe}
                                selectedForm={selectedForm}
                                horizontalLastIndex={horizontalLastIndex}
                                verticalLastIndex={verticalLastIndex}
                                centerVerticalIndex={centerVerticalIndex}
                                n1LastIndex={n1LastIndex}
                                n2LastIndex={n2LastIndex}
                                n3LastIndex={n3LastIndex}
                                acceptedCoords={acceptedCoords}
                                draggedElementCoords={draggedElementCoords}
                                droppedCoords={droppedCoords}
                                onDragStart={(dragCoords) => {
                                    

                                    if (dragCoords) {
                                        isDropAccepted.current = true
                                        pickedStripe.current = dragCoords
                                        setSelectedStripe(JSON.parse(dragCoords)[0])
                                        setSelectedForm(JSON.parse(dragCoords)[2])
                                        setSelectedShape(JSON.parse(dragCoords)[3])
                                        setDraggedElementCoords(dragCoords)
                                        
                                    }
                                }}
                                isShapeShake={isShapeShake}
                            />
                            // return <div className={`flex text-sm`}>
                            //     {index + ' ' + ind}
                            // </div>
                        })}
                    </div>
                })}
            </div>
            <div className='h-[50px] flex'>
                <div className='flex justify-center items-center' style={{ width: width / (18 / 5) + 1.5 }}>
                    <p className='text-lg'>n = 1</p>
                </div>
                <div className='flex justify-center items-center' style={{ width: width / (18 / 11) - (width / (18 / 5) + 1.5) }}>
                    <p className='text-lg'>n = 2</p>
                </div>
                <div className='flex justify-center items-center' style={{ width: width - (width / (18 / 11)) }}>
                    <p className='text-lg'>n = 3</p>
                </div>
            </div>
        </div>

        <div className='flex justify-between'>
            <div className='px-[3px]'>
                <div className='border border-black bg-[#ffff55] p-1 w-60' style={{}}>
                    <div style={{display: "flex",}}>
                        <p>Klikk på</p>
                        <div style={{left: 10, width: 25, height: 25, borderRadius: 15,  display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "white", marginLeft: 4, marginRight: 4}}>
                            <div className='rounded-full border-2 border-[#ff0000] h-[25px] w-[25px] flex justify-center items-center font-bold text-lg text-[#ff0000]'>?</div>
                        </div>
                        <p>øverst til høyre hvis</p>
                    </div>
                    <p>du vil ha en forklaring på hvordan du kan bruke appen.</p>
                </div>
            </div>
            <div className='flex gap-10'>
                <div className='px-0'>
                    <table>
                        <tr>
                            <td onClick={() => { setSelectedForm(-1) }} className={`cursor-pointer text-sm border border-red-500 px-4 text-center ${selectedForm === -1 ? 'bg-red-500' : ''}`}>
                                <Tooltip offset={17} multiline width={200} withArrow position='left-start' label="Klikk her hvis du ønsker at stripen skal inneholde en prikk mindre enn figurnummeret.">
                                    <div className='whitespace-nowrap'>
                                        n - 1
                                    </div>
                                </Tooltip>
                            </td>
                        </tr>
                        <tr>
                            <td onClick={() => { setSelectedForm(0) }} className={`cursor-pointer text-sm border border-red-500 px-4 text-center ${selectedForm === 0 ? 'bg-red-500' : ''}`}>
                                <Tooltip offset={17} multiline width={200} withArrow position='left-start' label="Klikk her hvis du ønsker at stripen skal inneholde like mange prikker som figurnummeret.">
                                    <div>n</div>
                                </Tooltip>
                            </td>
                        </tr>
                        <tr>
                            <td onClick={() => { setSelectedForm(1) }} className={`cursor-pointer text-sm border border-red-500 px-4 text-center ${selectedForm === 1 ? 'bg-red-500' : ''}`}>
                                <Tooltip offset={17} multiline width={200} withArrow position='left-start' label="Klikk her hvis du ønsker at stripen skal inneholde én prikk mer enn figurnummeret.">
                                    <div className='whitespace-nowrap'>
                                        n + 1
                                    </div>
                                </Tooltip>
                            </td>
                        </tr>
                    </table>
                </div>
                <div onDragOver={(e) => { e.preventDefault() }} onDrop={() => {
                    let temp = [...new Set(droppedCoords)]

                    if (pickedStripe.current) {
                        temp.splice(temp.indexOf(pickedStripe.current), 1)
                        pickedStripe.current = null
                    }
                    setDroppedCords([...temp])

                }}>
                    <div className='flex items-center gap-3 pr-[15px] mr-[10px]'>
                        <div className='flex gap-2 items-start'>
                            {shape?.map((item, index) => {
                                if (item[0] === 'stripe') {
                                    if (item[1] === 0) return <VerticalStripe key={index} draggable onDragStart={(e) => { isDropAccepted.current = true; setSelectedStripe(verticalStripe); setSelectedShape('stripe') }} selectedForm={selectedForm} />
                                    if (item[1] === 1) return <HorizontalStripe key={index} draggable onDragStart={(e) => { isDropAccepted.current = true; setSelectedStripe(horizontalStripe); setSelectedShape('stripe') }} selectedForm={selectedForm} />
                                }
                                else if (item[0] === 'rectangle') {
                                    return <div className='flex flex-col justify-center items-center gap-1'><RectangleStripe key={index} draggable onDragStart={(e) => { isDropAccepted.current = true; setSelectedStripe(verticalStripe); setSelectedShape('rectangle') }} selectedForm={selectedForm} />
                                    <Tooltip withArrow position='bottom-start' width={220} multiline label="Klikk her hvis du ønsker hjelp til å lage en formel for antall prikker i et kvadrat.">
                                        <div onClick={() => {
                                            setUrl("https://vimeo.com/932645608/361b327beb?share=copy")
                                            setIsPlayerActive(true)
                                        }} className='rounded-full border-2 border-[#ff0000] h-[30px] w-[30px] flex justify-center items-center font-bold text-lg text-[#ff0000]'>?</div>
                                    </Tooltip>
                                    </div>
                                }
                                else if (item[0] === 'triangle1') {
                                    return <div className='flex flex-col justify-center items-center gap-1'><Triangle1Stripe key={index} draggable onDragStart={(e) => { isDropAccepted.current = true; setSelectedStripe(verticalStripe); setSelectedShape('triangle1') }} selectedForm={selectedForm} />
                                                <Tooltip withArrow position='bottom-start' width={220} multiline label="Klikk her hvis du ønsker hjelp til å lage en formel for antall prikker i en trekant.">
                                                    <div onClick={() => {
                                                        setUrl("https://vimeo.com/934929405/8b2e5fdb61?share=copy")
                                                        setIsPlayerActive(true)
                                                    }} className='rounded-full border-2 border-[#ff0000] h-[30px] w-[30px] flex justify-center items-center font-bold text-lg text-[#ff0000]'>?</div>
                                                </Tooltip>
                                            </div>
                                }
                                else if (item[0] === 'triangle2') {
                                    return <div className='flex flex-col justify-center items-center gap-1'><Triangle2Stripe key={index} draggable onDragStart={(e) => { isDropAccepted.current = true; setSelectedStripe(verticalStripe); setSelectedShape('triangle2') }} selectedForm={selectedForm} />
                                                <Tooltip withArrow position='bottom-start' width={220} multiline label="Klikk her hvis du ønsker hjelp til å lage en formel for antall prikker i en trekant.">
                                                    <div onClick={() => {
                                                        setUrl("https://vimeo.com/934929405/8b2e5fdb61?share=copy")
                                                        setIsPlayerActive(true)
                                                    }} className='rounded-full border-2 border-[#ff0000] h-[30px] w-[30px] flex justify-center items-center font-bold text-lg text-[#ff0000]'>?</div>
                                                </Tooltip>
                                            </div>
                                }
                                else if (item[0] === 'triangle3') {
                                    return <div className='flex flex-col justify-center items-center gap-1'><Triangle3Stripe key={index} draggable onDragStart={(e) => { isDropAccepted.current = true; setSelectedStripe(verticalStripe); setSelectedShape('triangle3') }} selectedForm={selectedForm} />
                                                <Tooltip withArrow position='bottom-start' width={220} multiline label="Klikk her hvis du ønsker hjelp til å lage en formel for antall prikker i en trekant.">
                                                    <div onClick={() => {
                                                        setUrl("https://vimeo.com/934929405/8b2e5fdb61?share=copy")
                                                        setIsPlayerActive(true)
                                                    }} className='rounded-full border-2 border-[#ff0000] h-[30px] w-[30px] flex justify-center items-center font-bold text-lg text-[#ff0000]'>?</div>
                                                </Tooltip>
                                            </div>
                                }
                                else if (item[0] === 'triangle4') {
                                    return <div className='flex flex-col justify-center items-center gap-1'><Triangle4Stripe key={index} draggable onDragStart={(e) => { isDropAccepted.current = true; setSelectedStripe(verticalStripe); setSelectedShape('triangle4') }} selectedForm={selectedForm} />
                                                            <Tooltip withArrow position='bottom-start' width={220} multiline label="Klikk her hvis du ønsker hjelp til å lage en formel for antall prikker i en trekant.">
                                                    <div onClick={() => {
                                                        setUrl("https://vimeo.com/934929405/8b2e5fdb61?share=copy")
                                                        setIsPlayerActive(true)
                                                    }} className='rounded-full border-2 border-[#ff0000] h-[30px] w-[30px] flex justify-center items-center font-bold text-lg text-[#ff0000]'>?</div>
                                                </Tooltip>
                                            </div>
                                }
                                return <></>

                            })}

                            {/* {shape === 'stripe' ? <>
                                {availableShapes.includes(0) && <VerticleStripe draggable onDragStart={(e) => { isDropAccepted.current = true; setSelectedStripe(verticalStripe) }} selectedForm={selectedForm} />}
                                {availableShapes.includes(1) && <HorizontalStripe draggable onDragStart={(e) => { isDropAccepted.current = true; setSelectedStripe(horizontalStripe) }} selectedForm={selectedForm} />}
                            </> : <></>
                            } */}




                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col w-[489px]'>
                <div className={`border border-1 ${getFormulaClassName().field} ${isShake ? 'input-invalid' : ''} rounded-md p-2 flex justify-between`}>
                    <Tooltip withArrow position='bottom-start' width={220} multiline label="Klikk her hvis du ønsker å skrive inn en formel.">
                        <div className='flex'>
                            <p onClick={() => {
                                // console.log(document.getElementsByTagName('textarea')[0])
                                document.getElementsByTagName('textarea')[0].focus()
                            }}>FORMEL:</p>
                            <div className='w-64'>
                                <EquationEditor
                                    key={seed}
                                    value={equation}
                                    onChange={setEquation}
                                    autoCommands="pi theta sqrt sum prod alpha beta gamma rho"
                                    autoOperatorNames="sin cos tan"
                                    onEnter={() => { checkEquation()} }
                                />
                            </div>
                        </div>
                    </Tooltip>
                    <Tooltip withArrow position='top-start' width={220} multiline label="Klikk her hvis du ønsker hjelp til å skrive inn en formel.">
                        <div onClick={() => {
                                        setUrl("https://vimeo.com/932645664/6d532cbdd5?share=copy")
                                        setIsPlayerActive(true)
                                    }} className='rounded-full border-2 border-[#ff0000] h-[30px] w-[30px] flex justify-center items-center font-bold text-lg text-[#ff0000]'>?</div>
                    </Tooltip>
                </div>
                <div className='mt-4 self-end'>
                    <Tooltip withArrow position='left-start' width={250} multiline label="Klikk her hvis du ønsker å sjekke om formelen din er riktig.">
                        <div onClick={() => {checkEquation()}
                        } className='cursor-pointer text-sm border border-[#ff0000] rounded-md px-5 py-1'>
                            SJEKK FORMELEN
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    </div >
}

const ArrowRight = ({ disabled, onClick }) => <div onClick={onClick} style={{
    width: 0,
    height: 0,
    borderTop: '15px solid transparent',
    borderBottom: '15px solid transparent',
    borderLeft: `15px solid ${disabled ? '#ff000060' : "#ff0000"}`,
}}
/>

const ArrowLeft = ({ disabled, onClick }) => <div onClick={onClick} style={{
    width: 0,
    height: 0,
    borderTop: '15px solid transparent',
    borderBottom: '15px solid transparent',
    borderRight: `15px solid ${disabled ? '#ff000060' : "#ff0000"}`,
}}
/>


export default forwardRef(FigureNumber)