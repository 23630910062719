import { balansetallene } from "./balansetallene";
import { båttallene } from "./båttallene";
import { eiffeltallene } from "./eiffeltallene";
import { hundetallene } from "./hundetallene";
import { hustallene } from "./hustallene";
import { krysstallene } from "./krysstallene";
import { omkretstallene } from "./omkretstallene";
import { ovaltallene } from "./ovaltallene";
import { rektangeltallene } from "./rektangeltallene";
import { sløyfetallene } from "./sløyfetallene";
import { stoltallene } from "./stoltallene";
import { trapestallene } from "./trapestallene";

export const shapesConfig = {
    krysstallene: krysstallene,
    trapestallene: trapestallene,
    ovaltallene: ovaltallene,
    stoltallene: stoltallene,
    omkretstallene: omkretstallene,
    rektangeltallene: rektangeltallene,
    balansetallene: balansetallene,
    båttallene: båttallene,
    hustallene: hustallene,
    eiffeltallene: eiffeltallene,
    sløyfetallene: sløyfetallene,
    hundetallene: hundetallene,
}