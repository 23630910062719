import React, { useRef, useState} from "react";

import { Play } from "phosphor-react";
import ReactPlayer from "react-player/lazy";

const VideoPlayer = ({setIsPlayerActive, url}) =>  {
  const playerRef = useRef()

  const height = window.innerHeight
  const width = window.innerWidth


  return (
    <div style={{display: "flex", flex: 1, height: window.innerHeight, width: window.innerWidth, alignItems: "center", justifyContent: "center"}}>
      <div style={{backgroundColor: "white", borderRadius: 30, width: width -width/5, height: height-height/4, zIndex: 10, paddingTop: 40}}>
      <div onClick={() => {setIsPlayerActive(false)}} style={{position: "absolute",width: 80, height: 80, top: 50, left: 50}}>
        <div style={{position: "absolute", backgroundColor: "black", width: 50, height: 12.5, borderRadius: 2.5, rotate: "45deg"}}></div>
        <div style={{position: "absolute", backgroundColor: "black", width: 50, height: 12.5, borderRadius: 2.5, rotate: "-45deg"}}></div>
      </div>
        <ReactPlayer
          id="MyId"
          width="100%"
          height="100%"
          controls
          playing
          light="img/main-page/image.webp"
          playIcon={
            <button
              bgColor="var(--ifm-btn-primary)"
              textColor="var(--ifm-text-black)"
              borderRadius={40}
              fontSize={20}
              px={25}
              py={25}
              style={{
                maxWidth: window.innerWidth-20,
                width: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 10
              }}
            >
              <Play weight="fill" color="--ifm-text-black" size={50} />
            </button>
          }
          url={url}
        ></ReactPlayer>
      </div>
      
      <div onClick={() => {setIsPlayerActive(false)}} style={{position: "absolute", height: window.innerHeight, backgroundColor:"black", opacity: 0.4, width: window.innerWidth, zIndex: 5}}></div>
    </div>
  );
}

export default VideoPlayer