
function stringify(val) {
    return JSON.stringify(val)
}

// const horizontalStripe = 1
const verticalStripe = 0



// const verticalLength = 15
// const verticalLastIndex = verticalLength - 1

// const centerVerticalIndex = verticalLastIndex / 2
const n1CenterIndex = 2
const n2CenterIndex = 8
const n3CenterIndex = 14



const n1 = [
    stringify([6, 2]),
    stringify([6, 3]),
    stringify([7, 2]),
    stringify([8, 2]),
    stringify([8, 3]),
]

const n2 = [

    stringify([6, 7]),
    stringify([6, 9]),
    stringify([7, 7]),
    stringify([7, 8]),
    stringify([8, 7]),
    stringify([8, 8]),
    stringify([8, 9]),
    stringify([9, 7]),
    stringify([9, 8]),
    stringify([10, 7]),
    stringify([10, 9]),


]

const n3 = [

    stringify([4, 13]),
    stringify([4, 16]),

    stringify([5, 13]),
    stringify([5, 15]),

    stringify([6, 13]),
    stringify([6, 14]),
    stringify([6, 15]),
    stringify([6, 16]),

    stringify([7, 13]),
    stringify([7, 14]),
    stringify([7, 15]),

    stringify([8, 13]),
    stringify([8, 14]),
    stringify([8, 15]),
    stringify([8, 16]),

    stringify([9, 13]),
    stringify([9, 15]),

    stringify([10, 13]),
    stringify([10, 16]),





]

const acceptedCoords = [

    //n1 stripe
    stringify([verticalStripe, n1[0], 0, 'stripe']),
    stringify([verticalStripe, n1[1], 0, 'stripe']),
    stringify([verticalStripe, n1[2], 0, 'stripe']),
    stringify([verticalStripe, n1[3], 0, 'stripe']),
    stringify([verticalStripe, n1[4], 0, 'stripe']),

    stringify([verticalStripe, n1[0], -1, 'stripe']),
    stringify([verticalStripe, n1[1], -1, 'stripe']),
    stringify([verticalStripe, n1[2], -1, 'stripe']),
    stringify([verticalStripe, n1[3], -1, 'stripe']),
    stringify([verticalStripe, n1[4], -1, 'stripe']),

    stringify([verticalStripe, n1[0], 1, 'stripe']),
    stringify([verticalStripe, n1[1], 1, 'stripe']),


    //n1 triangle 3
    stringify([verticalStripe, n1[0], 0, 'triangle3']),
    stringify([verticalStripe, n1[1], 0, 'triangle3']),
    stringify([verticalStripe, n1[2], 0, 'triangle3']),
    stringify([verticalStripe, n1[3], 0, 'triangle3']),
    stringify([verticalStripe, n1[4], 0, 'triangle3']),

    stringify([verticalStripe, n1[0], -1, 'triangle3']),
    stringify([verticalStripe, n1[1], -1, 'triangle3']),
    stringify([verticalStripe, n1[2], -1, 'triangle3']),
    stringify([verticalStripe, n1[3], -1, 'triangle3']),
    stringify([verticalStripe, n1[4], -1, 'triangle3']),

    stringify([verticalStripe, n1[0], 1, 'triangle3']),

    //n1 triangle 4
    stringify([verticalStripe, n1[0], 0, 'triangle4']),
    stringify([verticalStripe, n1[1], 0, 'triangle4']),
    stringify([verticalStripe, n1[2], 0, 'triangle4']),
    stringify([verticalStripe, n1[3], 0, 'triangle4']),
    stringify([verticalStripe, n1[4], 0, 'triangle4']),

    stringify([verticalStripe, n1[0], -1, 'triangle4']),
    stringify([verticalStripe, n1[1], -1, 'triangle4']),
    stringify([verticalStripe, n1[2], -1, 'triangle4']),
    stringify([verticalStripe, n1[3], -1, 'triangle4']),
    stringify([verticalStripe, n1[4], -1, 'triangle4']),

    stringify([verticalStripe, n1[1], 1, 'triangle4']),


    //n2 stripe
    stringify([verticalStripe, n2[0], 0, 'stripe']),
    stringify([verticalStripe, n2[1], 0, 'stripe']),
    stringify([verticalStripe, n2[2], 0, 'stripe']),
    stringify([verticalStripe, n2[3], 0, 'stripe']),
    stringify([verticalStripe, n2[4], 0, 'stripe']),
    stringify([verticalStripe, n2[6], 0, 'stripe']),


    stringify([verticalStripe, n2[0], -1, 'stripe']),
    stringify([verticalStripe, n2[1], -1, 'stripe']),
    stringify([verticalStripe, n2[2], -1, 'stripe']),
    stringify([verticalStripe, n2[3], -1, 'stripe']),
    stringify([verticalStripe, n2[4], -1, 'stripe']),
    stringify([verticalStripe, n2[6], -1, 'stripe']),
    stringify([verticalStripe, stringify([4,7]), -1, 'stripe']),
    stringify([verticalStripe, stringify([5,7]), -1, 'stripe']),
    stringify([verticalStripe, stringify([6,8]), -1, 'stripe']),
    stringify([verticalStripe, stringify([5,8]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,9]), -1, 'stripe']),



    stringify([verticalStripe, n2[0], 1, 'stripe']),
    stringify([verticalStripe, n2[1], 1, 'stripe']),



    //n2 triangle 3
    stringify([verticalStripe, n2[0], 0, 'triangle3']),
    stringify([verticalStripe, n2[2], 0, 'triangle3']),
    stringify([verticalStripe, n2[3], 0, 'triangle3']),
    stringify([verticalStripe, n2[4], 0, 'triangle3']),

    stringify([verticalStripe, stringify([4, 6]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([6, 6]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([8, 6]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([7, 6]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([5, 6]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([6, 7]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([5, 7]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([4, 8]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([7, 7]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([8, 8]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([6, 8]), -1, 'triangle3']),

    //n2 triangle 4
    stringify([verticalStripe, n2[1], 0, 'triangle4']),
    stringify([verticalStripe, n2[2], 0, 'triangle4']),
    stringify([verticalStripe, n2[3], 0, 'triangle4']),
    stringify([verticalStripe, n2[6], 0, 'triangle4']),

    stringify([verticalStripe, n2[1], 1, 'triangle4']),
    stringify([verticalStripe, n2[1], -1, 'triangle4']),
    stringify([verticalStripe, stringify([5, 9]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([7, 9]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([6, 10]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([4, 10]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([8, 10]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([7, 8]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([6, 8]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([4, 8]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([5, 8]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([8, 8]), -1, 'triangle4']),

    //n3 triangle 3
    stringify([verticalStripe, n3[0], 0, 'triangle3']),
    stringify([verticalStripe, n3[2], 0, 'triangle3']),
    stringify([verticalStripe, n3[4], 0, 'triangle3']),

    stringify([verticalStripe, n3[0], -1, 'triangle3']),
    stringify([verticalStripe, stringify([2,13]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([3,13]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([4,14]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([5,13]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([6,13]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([4,15]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([5,15]), -1, 'triangle3']),
    stringify([verticalStripe, stringify([3,15]), -1, 'triangle3']),
    stringify([verticalStripe, n3[0], 1, 'triangle3']),

    //n3 triangle 4
    stringify([verticalStripe, n3[1], 0, 'triangle4']),
    stringify([verticalStripe, n3[3], 0, 'triangle4']),
    stringify([verticalStripe, n3[7], 0, 'triangle4']),

    stringify([verticalStripe, n3[3], -1, 'triangle4']),
    stringify([verticalStripe, stringify([6,16]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([7,16]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([5,16]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([3,16]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([4,16]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([5,14]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([6,14]), -1, 'triangle4']),
    stringify([verticalStripe, stringify([4,14]), -1, 'triangle4']),
    stringify([verticalStripe, n3[1], 1, 'triangle4']),


    //n3 stripe
    stringify([verticalStripe, n3[0], 0, 'stripe']),
    stringify([verticalStripe, n3[1], 0, 'stripe']),
    stringify([verticalStripe, n3[2], 0, 'stripe']),
    stringify([verticalStripe, n3[3], 0, 'stripe']),
    stringify([verticalStripe, n3[4], 0, 'stripe']),
    stringify([verticalStripe, n3[7], 0, 'stripe']),


    stringify([verticalStripe, n3[0], -1, 'stripe']),
    stringify([verticalStripe, n3[1], -1, 'stripe']),
    stringify([verticalStripe, n3[2], -1, 'stripe']),
    stringify([verticalStripe, n3[3], -1, 'stripe']),
    stringify([verticalStripe, n3[4], -1, 'stripe']),
    stringify([verticalStripe, n3[7], -1, 'stripe']),
    stringify([verticalStripe, stringify([2,13]), -1, 'stripe']),
    stringify([verticalStripe, stringify([3,13]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,14]), -1, 'stripe']),
    stringify([verticalStripe, stringify([4,15]), -1, 'stripe']),
    stringify([verticalStripe, stringify([3,15]), -1, 'stripe']),
    stringify([verticalStripe, stringify([2,16]), -1, 'stripe']),


    stringify([verticalStripe, n3[0], 1, 'stripe']),
    stringify([verticalStripe, n3[1], 1, 'stripe']),
]

export const sløyfetallene = {

    n1: n1,
    n2: n2,
    n3: n3,
    acceptedCoords: acceptedCoords,

    n1CenterIndex: n1CenterIndex,
    n2CenterIndex: n2CenterIndex,
    n3CenterIndex: n3CenterIndex,

    shape: [['stripe', 0], ['triangle3', 0], ['triangle4', 0]],

    name: 'Sløyfetallene',
    formula: 'n ^ 2 + 3 n + 1',



}