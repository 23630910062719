import { MantineProvider } from '@mantine/core';
import './App.css';
import FigureNumber from './screens/FigureNumber';
import {useState, useEffect, useRef} from 'react'
import StartScreen from './screens/StartScreen';
import VideoPlayer from './components/ToolComponents/VideoPlayer';



function App() {

  const ref = useRef()

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [isPlayerActive, setIsPlayerActive] = useState()
  const [url, setUrl] = useState("")


  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight)
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{position: "relative", height: height, width: width}} >
    <div style={{height: height, width: width}} onDragOver={(e) => { e.preventDefault() }} onDrop={() => {
      ref.current.onDropOutside()
  }}></div>
      {/* <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
       <StartScreen />
     </div> */}
      {isPlayerActive && <div style={{height: height, width: width, zIndex: 100, position: "absolute", alignItems: "center", justifyContent: "center", top:0, left: 0}}><VideoPlayer setIsPlayerActive={setIsPlayerActive} url={url}
      /></div>}
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' style={{}}>
        
        <FigureNumber ref={ref} setIsPlayerActive={setIsPlayerActive} setUrl={setUrl} />
      </div>
  
    </div>
    );
}

export default App;
