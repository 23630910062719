export const Triangle2Stripe = ({ selectedForm, ...props }) => {
    switch (selectedForm) {
        case -1:
            return <div {...props} className="relative">
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)", strokeDasharray: 2 }} points="1,49 25,1 49,49" class="triangle" />
                </svg>
                <svg style={{ position: 'absolute', top: 0 }} height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="6,39 25,1 44,39" class="triangle" />
                </svg>
            </div>
        case 0:
            return <div {...props}>
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="1,49 25,1 49,49" class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className="relative">
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="1,49 25,1 49,49" class="triangle" />
                </svg>
                <svg style={{ position: 'absolute', top: 0 }} height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="6,39 25,1 44,39" class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className='w-[50px] h-[50px] border-2 border-teal-600 rounded-md'></div>
    }
}

// Shapes to show on the dots
export const FirstContainerTriangle2 = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, ...props }) => {
    const width = ((containerHeight / centerVerticalIndex) + 35)
    const height = (containerHeight / centerVerticalIndex) - 10

    const absWidth = width - 12
    const absHeight = height - 16
    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute top-[50%] left-[50%] translate-y-[-60%] translate-x-[-50%] h-[20px] w-[20px]`}>
                <svg height={20} width={20}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points="1,19 10,1 19,19" class="triangle" />
                </svg>
            </div>
        case -1:
            return <div {...props} className={`absolute top-[50%] left-[50%] translate-y-[-60%] translate-x-[-50%] h-[20px] w-[20px]`}>
                <svg height={20} width={20}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)", strokeDasharray: 2 }} points="1,19 10,1 19,19" class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-25%] translate-x-[-46%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height - 1} ${width / 2},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
                <svg height={height} width={width} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`16,${absHeight} ${width / 2},1 ${absWidth - 4},${absHeight}`} class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className={`absolute top-[50%] left-[50%] translate-y-[-60%] translate-x-[-50%] h-[20px] w-[20px]`}>
                <svg height={20} width={20}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points="1,19 10,1 19,19" class="triangle" />
                </svg>
            </div>

    }
}

export const SecondContainerTriangle2 = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, ...props }) => {
    const width = ((containerHeight / centerVerticalIndex) + 35)
    const height = (containerHeight / centerVerticalIndex) - 10

    const absWidth = width - 12
    const absHeight = height - 16

    const height1 = (containerHeight / centerVerticalIndex) + 25
    const width1 = ((containerWidth / horizontalLastIndex) + 14) * 2.1
    

    const absWidth1 = width1 - 12
    const absHeight1 = height1 - 20
    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute top-[60%] translate-y-[-25%] translate-x-[-46%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height - 1} ${width / 2},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
            </div>
        case -1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-25%] translate-x-[-46%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)", strokeDasharray: 2 }} points={`1,${height - 1} ${width / 2},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
                <svg height={height} width={width} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`16,${absHeight} ${width / 2},1 ${absWidth - 4},${absHeight}`} class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-20%] translate-x-[-48%]`}>
                <svg height={height1} width={width1}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height1 - 1} ${width1 / 2},1 ${width1 - 1},${height1 - 1}`} class="triangle" />
                </svg>
                <svg height={absHeight1} width={absWidth1} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`20,${absHeight1 - 1} ${width1 / 2},1 ${absWidth1 - 8},${absHeight1 - 1}`} class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className={`absolute top-[60%] translate-y-[-10%] translate-x-[-15%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width - 1},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
            </div>

    }
}

export const ThirdContainerTriangle2 = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, ...props }) => {

    const height = (containerHeight / centerVerticalIndex) + 25
    const width = ((containerWidth / horizontalLastIndex) + 14) * 2.1

    const absWidth = width - 12
    const absHeight = height - 20

    const height1 = ((containerHeight / centerVerticalIndex) + 6) * 1.8
    const width1 = ((containerWidth / horizontalLastIndex) + 7) * 3.5

    const absWidth1 = width1 - 12
    const absHeight1 = height1 - 20


    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute top-[60%] translate-y-[-20%] translate-x-[-48%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height - 1} ${width / 2},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
            </div>
        case -1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-20%] translate-x-[-48%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)", strokeDasharray: 2 }} points={`1,${height - 1} ${width / 2},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
                <svg height={absHeight} width={absWidth} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`20,${absHeight - 1} ${width / 2},1 ${absWidth - 8},${absHeight - 1}`} class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-15%] translate-x-[-48%]`}>
                <svg height={height1} width={width1}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height1 - 1} ${width1 / 2},1 ${width1 - 1},${height1 - 1}`} class="triangle" />
                </svg>
                <svg height={absHeight1} width={absWidth1} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`24,${absHeight1 - 3} ${width1 / 2},1 ${absWidth1 - 12},${absHeight1 - 3}`} class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className={`absolute top-[60%] translate-y-[-20%] translate-x-[-48%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,${height - 1} ${width / 2},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
            </div>

    }
}

