export const Triangle1Stripe = ({ selectedForm, ...props }) => {
    switch (selectedForm) {
        case -1:
            return <div {...props} className="relative">
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)", strokeDasharray: 2 }} points="1,1 49,1 49,49" class="triangle" />
                </svg>
                <svg style={{ position: 'absolute', top: 0 }} height={40} width={40}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="1,1 39,1 39,39" class="triangle" />
                </svg>
            </div>
        case 0:
            return <div {...props}>
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="1,1 49,1 49,49" class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className="relative">
                <svg height={50} width={50}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="1,1 49,1 49,49" class="triangle" />
                </svg>
                <svg style={{ position: 'absolute', top: 0 }} height={40} width={40}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: "rgb(13 148 136)" }} points="1,1 39,1 39,39" class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className='w-[50px] h-[50px] border-2 border-teal-600 rounded-md'></div>
    }
}

// Shapes to show on the dots
export const FirstContainerTriangle1 = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, ...props }) => {
    const width = ((containerWidth / horizontalLastIndex) + 15) + 10
    const height = ((containerHeight / centerVerticalIndex) + 13) + 15

    const absWidth = width - 12
    const absHeight = height - 12
    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute top-[50%] left-[50%] translate-y-[-30%] translate-x-[-65%]`}>
                <svg height={20} width={20}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points="1,1 19,1 19,19" class="triangle" />
                </svg>
            </div>
        case -1:
            return <div {...props} className={`absolute top-[50%] left-[50%] translate-y-[-30%] translate-x-[-65%] h-[20px] w-[20px]`}>
                <svg height={20} width={20}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)", strokeDasharray: 4 }} points="1,1 19,1 19,19" class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-10%] translate-x-[-15%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width - 1},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
                <svg height={absHeight} width={absWidth} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${absWidth - 22},1 ${absWidth - 22},${absHeight - 22}`} class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className={`absolute top-[50%] left-[50%] translate-y-[-30%] translate-x-[-65%] h-[20px] w-[20px]`}>
                <svg height={20} width={20}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points="1,1 19,1 19,19" class="triangle" />
                </svg>
            </div>

    }
}

export const SecondContainerTriangle1 = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, ...props }) => {
    const width = ((containerHeight / centerVerticalIndex) + 15) + 10
    const height = ((containerHeight / centerVerticalIndex) + 13) + 10

    const absWidth = width - 12
    const absHeight = height - 12


    const height1 = (((containerHeight / centerVerticalIndex) + 6) * 2) + 12
    const width1 = (((containerWidth / horizontalLastIndex) + 7) * 2) + 12

    const absWidth1 = width1 - 20
    const absHeight1 = height1 - 19

    console.log("Her er valgt form", selectedForm)

    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute top-[60%] translate-y-[-44%] translate-x-[-51%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width - 1},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
            </div>
        case -1:
            return <div {...props} className={`absolute top-[60%] translate-y-[-44%] translate-x-[-51%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)", strokeDasharray: 2 }} points={`2,2 ${width - 1},2 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
                <svg height={absHeight} width={absWidth} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`2,2 ${absWidth - 19},2 ${absWidth - 20},${absHeight - 19}`} class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className={`absolute  translate-y-[-45%] translate-x-[-51%]`}>
                <svg height={height1} width={width1}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width1 - 1},1 ${width1 - 1},${height1 - 1}`} class="triangle" />
                </svg>
                <svg height={absHeight1} width={absWidth1} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${absWidth1 - 22},1 ${absWidth1 - 22},${absHeight1 - 21}`} class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className={`absolute translate-y-[-10%] translate-x-[-15%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width - 1},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
            </div>

    }
}

export const ThirdContainerTriangle1 = ({ containerHeight, centerVerticalIndex, containerWidth, horizontalLastIndex, isAccepted, selectedForm, selectedStripe, ...props }) => {

    const height = (((containerHeight / centerVerticalIndex) + 6) * 2) + 12
    const width = (((containerWidth / horizontalLastIndex) + 7) * 2) + 12

    const absWidth = width - 20
    const absHeight = height - 19

    const height1 = (((containerHeight / centerVerticalIndex) + 6) * 2.9) + 14
    const width1 = (((containerWidth / horizontalLastIndex) + 7) * 2.9) + 14

    const absWidth1 = width1 - 22
    const absHeight1 = height1 - 21

    switch (selectedForm) {
        case 0:
            return <div {...props} className={`absolute translate-y-[-43%] translate-x-[-50%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width - 1},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
            </div>
        case -1:
            return <div {...props} className={`absolute translate-y-[-43%] translate-x-[-50%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)", strokeDasharray: 2 }} points={`1,1 ${width - 1},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
                <svg height={absHeight} width={absWidth} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${absWidth - 19},1 ${absWidth - 19},${absHeight - 18}`} class="triangle" />
                </svg>
            </div>
        case 1:
            return <div {...props} className={`absolute translate-y-[-30%] translate-x-[-65%]`}>
                <svg height={height1} width={width1}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width1 - 1},1 ${width1 - 1},${height1 - 1}`} class="triangle" />
                </svg>
                <svg height={absHeight1} width={absWidth1} style={{ position: 'absolute', top: 0 }}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${absWidth1 - 22},1 ${absWidth1 - 22},${absHeight1 - 22}`} class="triangle" />
                </svg>
            </div>
        default:
            return <div {...props} className={`absolute top-[60%] translate-y-[-10%] translate-x-[-15%]`}>
                <svg height={height} width={width}>
                    <polygon style={{ fill: 'transparent', strokeWidth: 2, stroke: isAccepted ? "rgb(13 148 136)" : "rgb(220 38 38)" }} points={`1,1 ${width - 1},1 ${width - 1},${height - 1}`} class="triangle" />
                </svg>
            </div>
    }
}